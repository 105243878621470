import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Popover } from 'antd';
import Ts from 'components/LocaleTranslator';
import { getNavigationItems, tagLink } from 'common/config/navigation';
import SvgIcon from 'components/SvgIcon';
// import { Alert } from 'antd';
import './index.scss';

const NavLink = ({ href, isOuterLink = false, children, lang }) => {
  href = href.replace('{{lang}}', lang);
  if (isOuterLink) {
    return (
      <a target="_blank" href={href} rel="noopener noreferrer">
        {children}
      </a>
    );
  } else if (href) {
    return <Link to={href}>{children}</Link>;
  } else {
    return children;
  }
};
const NavTag = ({ item, lang }) => {
  if (!item?.tagName) return null;
  if (tagLink[item.tagName]) {
    lang = tagLink[item.tagName][lang]
      ? lang
      : ['zh-CN', 'zh-HK'].includes(lang)
      ? 'zh-CN'
      : 'en';
    return (
      <NavLink
        href={tagLink[item.tagName][lang]}
        lang={lang}
        isOuterLink={true}
      >
        <div className="nav-tag-badge">
          <Ts id={`common.nav.tags.${item.tagName}`} />
        </div>
      </NavLink>
    );
  }
  return (
    item?.tagName && (
      <div className="nav-tag-badge">
        <Ts id={`common.nav.tags.${item.tagName}`} />
      </div>
    )
  );
};
/**
 *  Group By key
 * @param array
 * @param key
 * @returns
 */
export const groupBy = (array, key) => {
  return array.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
const Level2MenuContent = ({ lang, levelOneSlug, extendWidth }) => {
  const navItem = getNavigationItems(lang).find(
    (item) => item.slug === levelOneSlug,
  );
  const currentLev2 = navItem;
  const level2Items = navItem?.subItems || [];
  let realLang = lang;
  if (navItem?.supportLangs) {
    realLang = ['zh-CN', 'zh-HK'].includes(realLang) ? 'zh-CN' : lang;
    realLang = navItem?.supportLangs?.includes(realLang) ? realLang : 'en';
  }
  // 是否显示客服弹窗
  const getLev3List = (level3Items) => {
    const curLev3Items = level3Items;

    if (curLev3Items) {
      const groups = groupBy(curLev3Items, 'groupId');

      return (
        <div className="nav-level-three-list">
          {Object.keys(groups).map((item) => {
            return (
              <ul key={item} className="nav-level-three-group">
                {groups[item].map((gitem) => (
                  <li key={gitem.slug} className="relative">
                    <NavLink
                      href={
                        navItem?.isSpecificChain && !gitem.isSpecificChain
                          ? `${navItem.path}${gitem.path}`
                          : gitem.path
                      }
                      isOuterLink={gitem.isOuterLink}
                      lang={realLang}
                    >
                      <span className="hot-wrap">
                        {<Ts id={`common.nav.${gitem.slug}`} />}
                        {gitem.isHot && (
                          <SvgIcon
                            className="nav-hot-icon hot-icon"
                            size="xs"
                            iconName={'ic_hot'}
                          />
                        )}
                      </span>
                    </NavLink>
                    <NavTag item={gitem} lang={realLang} />
                  </li>
                ))}
              </ul>
            );
          })}
        </div>
      );
    }
  };
  if (!level2Items) return null;

  const level3Items = navItem?.mulipleColumns
    ? level2Items
    : level2Items?.find((item) => item.slug === currentLev2?.slug)?.subItems ||
      [];
  const hasLev3 = level3Items.length > 0;
  return (
    <div className="nav-level-two">
      {!navItem?.mulipleColumns && (
        <ul className={`nav-level-two-list`}>
          {level2Items.map((item) => {
            return (
              <NavLink
                href={item.path}
                key={item.slug}
                isOuterLink={item.isOuterLink}
                lang={realLang}
              >
                <li>
                  <div className="flex items-center">
                    {item.iconName && (
                      <SvgIcon className="nav-icon" iconName={item.iconName} />
                    )}
                    {item.picName && (
                      <img
                        className="nav-icon"
                        src={require(`images/${item.picName}.png`).default}
                        width={32}
                        height={32}
                      />
                    )}
                    <span>
                      <Ts id={`common.nav.${item.slug}`} />
                    </span>
                    {item.isHot && (
                      <SvgIcon
                        className="nav-hot-icon"
                        size="14"
                        iconName={'ic_hot'}
                      />
                    )}
                    {item.tag && (
                      <span className="nav-level-two-tag">{item.tag}</span>
                    )}
                  </div>

                  <NavTag item={item} lang={realLang} />
                </li>
              </NavLink>
            );
          })}
        </ul>
      )}
      {hasLev3 && (
        <div className="nav-level-three" style={{ width: extendWidth }}>
          {getLev3List(level3Items)}
        </div>
      )}
    </div>
  );
};

@withRouter
@inject('store')
@observer
class Navigation extends Component {
  constructor(props) {
    super(props);
    this.appStore = this.props.store.appStore;
    this.userStore = this.props.store.userStore;

    this.state = {
      currentPath: '/',
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let currentPath = nextProps.location.pathname;
    return {
      currentPath,
    };
  }

  isActivePath = (lev1Path) => {
    const curPath = this.state.currentPath;
    return (
      (lev1Path === '/') &
        (curPath === '/' ||
          curPath === '/pool-stats' ||
          curPath === '/tools') ||
      (lev1Path === curPath &&
        (curPath === '/app-download' || curPath === '/bitdeer'))
    );
  };

  render() {
    const { lang } = this.appStore;
    return (
      <div className="flex items-center">
        <a className="header-logo" href="/">
          <img
            src={require(`images/V2/btccom_logo.png`).default}
            alt="logo"
          ></img>
        </a>
        <div className="nav-bar-el">
          <ul className="nav-bar-list">
            {getNavigationItems(lang)
              .filter((item) => !item.hide)
              .map((lev1, index) => {
                let realLang = lang;
                if (lev1.supportLangs) {
                  realLang = ['zh-CN', 'zh-HK'].includes(realLang)
                    ? 'zh-CN'
                    : lang;
                  realLang = lev1.supportLangs?.includes(realLang)
                    ? realLang
                    : 'en';
                }
                let extendWidth =
                  lev1.subNavCnExtendWidth && ['zh-CN', 'zh-TW'].includes(lang)
                    ? lev1.subNavCnExtendWidth
                    : lev1.subNavExtendWidth;
                const subNavExtendWidth = extendWidth ? extendWidth / 1 : 0;
                if (!lev1.subItems || !lev1.subItems.length) {
                  return (
                    <li className="nav-bar-item" key={lev1.slug}>
                      <button
                        className={`nav-bar-item-title ${
                          this.isActivePath(lev1.path) ? 'active' : ''
                        }`}
                      >
                        <NavLink
                          href={lev1.path}
                          isOuterLink={lev1.isOuterLink}
                          lang={realLang}
                        >
                          {<Ts id={`common.nav.${lev1.slug}`} />}
                        </NavLink>
                      </button>
                      <NavTag item={lev1} lang={realLang} />
                    </li>
                  );
                }
                return (
                  <Popover
                    trigger="hover"
                    content={
                      <Level2MenuContent
                        levelOneSlug={lev1.slug}
                        lang={lang}
                        extendWidth={subNavExtendWidth}
                      />
                    }
                    overlayClassName="nav-overlay"
                    key={lev1.slug}
                  >
                    <li className="nav-bar-item">
                      <button
                        className={`nav-bar-item-title ${
                          this.isActivePath(lev1.path) ? 'active' : ''
                        }`}
                      >
                        <NavLink
                          href={lev1.path}
                          isOuterLink={lev1.isOuterLink}
                          lang={realLang}
                        >
                          {<Ts id={`common.nav.${lev1.slug}`} />}
                          <SvgIcon
                            iconName="icon_menu_arrow_down"
                            size="menuArrow"
                            className="menu-arrow"
                          />
                        </NavLink>
                      </button>
                      <NavTag item={lev1} lang={realLang} />
                    </li>
                  </Popover>
                );
              })}
          </ul>
        </div>
      </div>
    );
  }
}
export default Navigation;
