import flatten from 'flat';
const pageMessage = {
  pages: {
    dashboard: {
      EarningsM: '{coinType} Madencilik Kazancı',
      accountEarning: 'Hesap Kazançları',
      active: 'Aktif',
      activeMiner: 'Aktif',
      amoutPaid: 'Ödenen Toplam Tutar',
      announcement: 'Duyuru',
      cannotConnect: 'Bağlanamıyor musunuz?',
      urlConfigGpu: ' normal GPU madencisini destekler',
      urlConfigNicehash: ' Nicehash için optimize edilmiştir',
      urlConfigGpuDcr: " Antminer DR3, Claymore, gominer'ı destekler",
      urlConfigNicehashDcr: ' Nicehash, Innosilicon madencisini destekler',
      customerTitle: 'Satış Sonrası Hizmet',
      dailyHashrate: 'Günlük',
      dashboard: 'Gösterge Paneli',
      earning: '{coinType} Kazanç',
      eventConnected: 'bağlı',
      eventDisconnected: 'bağlantı kesildi',
      eventMiner: 'Madenci',
      eventName: 'etkinlik',
      eventSkip: 'Atla',
      eventTitle: 'Etkinlikler',
      eventlatestLogs: 'Aşağıdaki etkinlikler öncekilerdir',
      hastrateChart: 'Hash Oranı Grafiği',
      history: 'Geçmiş',
      hour: '{n} S',
      hours: 'Saat',
      inActive: 'Etkin değil',
      lastPaymentTime: 'Son Ödeme Zamanı',
      markAllRead: 'Tümünü Okundu İşaretle',
      minerContent:
        "Yerel ağa erişin ve madencilerin arka plan sistemine giriş yapın, madenci adını girin ve profili kaydedin. Madenciler birkaç saniye içinde BTC.com Havuzu'na eklenecektir.<br/>Madenci ID ayarları: {sub_account}.001, {sub_account}.002, vb; her madenci ID bir madenciye karşılık gelir.",
      minerNameStand: `Madencinin isim standardı: alt hesap+'.'+sayı, örneğin, alt hesabınız {sub_account} ise, madencileriniz {sub_account}.001, {sub_account}.002 olarak ayarlanabilir. Her madencinin kendi adı vardır.`,
      minerName:
        '* {sub_account}.001, {sub_account}.002 gibi madencilerinizde &lt;alt-hesap&gt;.&lt;miner_ID&gt; ayarlayın.',
      miner: 'Madenci',
      miners: 'Madenciler',
      miner1: 'I Tipi Madenci',
      miner2: 'II-Tipi Madenci',
      miningAddress: 'Maden Adresi',
      miningEarnings: '{coinType} Madencilik Kazancı',
      minute: 'M',
      more: 'Daha Fazla',
      networkDiff: 'ağ Farkı',
      networkHashrate: 'Ağ Hash Oranı',
      networkStatus: 'Ağ durumu',
      nextDifficult: 'Tahmini Sonraki Zorluk',
      noMiner:
        'BTC.com havuzuna madencileri ekleyin ve madenciliğe başlayın. Şunun kullanılmasını tavsiye edilir ',
      btcTools: 'BTC Aracı.',
      noEvent: 'Etkinlik bildirimi yok!',
      nowHashrate: 'Gerçek zamanlı',
      pendingPayouts: 'Bekleyen Ödeme',
      poolHashrate: 'Havuz Hash Oranı',
      realHashrate: '{coinType} Hash Oranı',
      realtime: 'Gerçek Zaman',
      timeRemain: 'Kalan Zaman',
      title: 'Gösterge Paneli - BTC.com Havuzu',
      today: 'Bugünkü Tahmini',
      unpaid: 'Ödenmemiş',
      yesterday: 'Dün',
      yesterdayEarnTip:
        'UTC saatinden önceki gün 0:00-24:00 arasındaki kazançları ifade eder.',
      todayEarnTip:
        "Yalnızca BTC madenciliği yapan hash oranının tahmini geliri (Sha256'nın algoritması)",
      charts: {
        hashrate: 'Hash Oranı',
        minerCharts: 'Madenci Hash Oranı Grafikleri',
        myCharts: 'Hash Oranı Grafiklerim',
        poolCharts: 'BTC.com Havuz Hash Oranı Grafikleri',
        reject: 'Reddedildi',
        time: 'Zaman',
      },
      yesterday_earn: 'Dünün Kazancı',
      yesterday_hashhrate: 'Dün Kabul Edilen Hash Oranı',
      earnings_per_100T: '100T başına kazanç',
      //职责声明
      statement:
        '* Bu hizmete, sağladığımız veya kullanılmasını belirttiğimiz yöntemler dışında herhangi bir yolla erişmenize (veya erişmeye çalışmanıza) izin verilmez ve bu hizmete yasa dışı veya kanunsuz yollarla erişmemeyi (veya erişmeye çalışmamayı) veya bu hizmeti yasa dışı veya kanunsuz faaliyetleri veya amaçları desteklemek için kullanmamayı özellikle kabul edersiniz. Bu web sitesine girerek ve bu hizmete kaydolarak, bulunduğunuz yerdeki yargı yasalarına uygun şekilde bu hizmetin yasal olarak sağlanabileceği bir kişi olduğunuzu kabul ve beyan edersiniz.',
      // 收益预估
      estimatedEarnings: {
        title: 'Tahmini kazanç',
        hours24Earnings: 'Tahmini son 24 saatlik kazançlar',
        tip24: 'Para biriminin son 24 saatteki ortalama zorluğuna göre',
        realTimeEarnings: 'Tahmini gerçek zamanlı kazançlar',
        realTimeEarningsTip: 'Para biriminin en yeni madencilik zorluğuna göre',
      },
    },
    subAccount: {
      title: 'Bir alt hesap oluşturun - BTC.com havuzu',
      createWorker: 'Alt hesap oluştur',
      noNode: 'Henüz düğüm seçmediniz!',
      noCoinType: "Madencilik coin'ini henüz seçmediniz!",
      selectRegion: 'Bölgenizi seçin',
      setWorker: 'Bir alt hesap adı belirleyin',
      enterWorker: 'Alt hesap adını girin',
      enterAddress: 'Adresi Girin',
      coinTypeEx:
        'BTC.com Havuzu artık {supportCoins} madenciliğini destekliyor, diğer madeni paralar yakında gelecek, beklemede kalın.',
      regionEx:
        "BTC.com Havuzu'nun, dünya çapında sunucuları vardır ve en yakın sunucu size en iyi deneyimi sunar.",
      workerEx:
        'Bu alt hesap yukarıdaki bölgeye bağlanacak. 2000 taneye kadar alt hesap oluşturabilirsiniz. Madencilerinizde subaccount.001, subaccount.002, gibi <<sub-account>.<miner_name> ayarlayın.',
      workerCaution:
        '* Dikkat: Her alt hesap sadece bir bölgede madencilik yapabilir. Oluşturduğunuz bu alt hesap başka bölgelerde kullanılamaz. Başka bir bölgede madencilik yapmak istiyorsanız, lütfen başka bir bölgede başka bir alt hesap oluşturun. ',
      setAddress: 'Bir para çekme adresi belirleyin',
      save: 'Oluşturma ve Madencilik',
      verifyWorker: '3-20 harf veya sayı',
      option: 'İsteğe bağlı',
      optional:
        "İsteğe bağlı. BCH adresi BTC'ninkine benziyor, Lütfen dikkat edin ve yanlış adresi girmeyin.",
      'optional-BTC':
        "İsteğe bağlı. BCH eski adresi BTC'ninkine benziyor, Lütfen dikkat edin ve yanlış adresi girmeyin.",
      'optional-BCH':
        "İsteğe bağlı. BCH eski adresi BTC'ninkine benziyor, Lütfen dikkat edin ve yanlış adresi girmeyin. Yeni adres kullanmanız tavsiye edilir",
      'optional-UBTC':
        "İsteğe bağlı. BCH eski adresi UBTC'ninkine benziyor, Lütfen dikkat edin ve yanlış adresi girmeyin.",
      'optional-SBTC':
        "İsteğe bağlı. BCH eski adresi SBTC'ninkine benziyor, Lütfen dikkat edin ve yanlış adresi girmeyin.",
      'optional-coin':
        'İsteğe bağlı: Lütfen dikkat edin ve yanlış adresi girmeyin.',
      selectCoinType: "Madencilik coin'inizi seçin",
      automatic: 'Otomatik',
      recommandWallet: "BTC.com Cüzdanı'nı kullanmanızı öneririz",
      withdrawToExchange: 'Takas yardımı',
      withdrawToWallet: 'Cüzdan yardımı',
    },
    tools: {
      // title: 'Araçlar - BTC.com Havuzu',
      // tools: 'Araçlar',
      // fppsPps: 'FPPS ve PPS Dinamik Karşılaştırması',
      // aboutFpps: 'FPPS hakkında iyi olan nedir?',
      // charts: 'Grafikler her şeyi anlatabilir.',
      // watchCharts: 'Grafikleri İzle',
      // miniCalc: 'Madencilik Kâr Hesaplayıcı',
      // miniCalcExpress:
      //   'Fiyat, zorluk, hash oranı, elektrik maliyetleri, güç kullanımı vb. ile birlikte madencilik kârını hesaplayın',
      // miniMiniCalc: 'Mini Madencilik Kâr Hesaplayıcı',
      // miniMiniCalcExpress: 'Madencilik kârını hızlı ve en kolay şekilde hesaplayın',
      // strat: 'Başlat',
      // btcTool: 'BTC Aracı.',
      // btcToolIntroduce: "Madencilerin yapılandırmasını verimli bir şekilde yapmak için toplu işlem.",
      // download: 'İndirmek İçin Tıklayın',
      btcSmartAgent: 'BTC Smart Agent',
      agentExpress: 'Verimli ve Şeffaf <br/>Tek Çalışan Görülebilir',
      agentExpressBanner:
        'Verimli ve Şeffaf <i style="margin-right:20px"></i> Tek Çalışan Görünümlü',
      bit: 'bit',
      document: 'Windows Yardım Belgesi.',
      poolApp: 'Tam Özellikli BTC.com Havuz Uygulaması',
      poolRealTime:
        'Havuz ve madenci verilerini gerçek zamanlı olarak görüntüleyin',
      poolOpeation: 'Madencileri direkt silin/değiştirin',
      poolComming: 'Etkinlik uyarı işlevi çok yakında',
      poolDownlaod: 'İndirmek İçin Tıklayın',
      title: 'Araçlar - BTC.com Havuzu',
      tools: 'Araçlar',
      fppsPps: 'FPPS ve PPS Dinamik Karşılaştırması',
      aboutFpps: 'FPPS hakkında iyi olan nedir?',
      charts: 'Grafikler her şeyi anlatabilir.',
      watchCharts: 'Grafikleri İzle',
      miniCalc: 'Madencilik Kâr Hesaplayıcı',
      miniCalcExpress:
        'Fiyat, zorluk, hash oranı, elektrik maliyetleri, güç kullanımı vb. ile birleştirilmiş şekilde madencilik kârını hesaplayın',
      miniMiniCalc: 'Mini Madencilik Kâr Hesaplayıcı',
      miniMiniCalcExpress:
        'Madencilik kârını hızlı ve en kolay şekilde hesaplayın',
      start: 'Başlat',
      btcTool: 'BTC Aracı.',
      btcToolIntroduce:
        'Madencilerin verimli bir şekilde yapılandırılması için toplu işlem.',
      ipToolsTitle: 'Madencilerin Statik IP Araçlarını Ayarlayın',
      ipToolsDes:
        "Tüm Antminer'ları, meşgul IP Yapılandırması'nı, havuza doğrudan bağlantıyı yapılandırmasını destekler",
    },
    miners: {
      createGroup: 'Yeni Bir Grup Oluştur',
      default: 'VARSAYILAN',
      addGroup: 'Yeni Bir Grup Oluştur',
      deleteGroupTitle: 'Grubu Sil',
      editGroupTitle: 'Grubu Düzenle',
      addGroupInfo:
        'Not: Seçilen madenciler otomatik olarak yeni gruba eklenecektir.',
      groupNameLabel: 'Grup Adı',
      addGroupEmpty: 'Grup adı boş olamaz.',
      groupRejected: 'Gerçek zamanlı reddetme oranı',
      groupNameLimit: 'Grup adı 3 - 20 basamak arasında olmalıdır',
      all: 'Tümü',
      inactive: 'Etkin değil',
      active: 'Aktif',
      dead: 'Ölü',
      moveTo: 'Taşı',
      remove: 'Kaldır',

      allTooltip:
        'Şunu içerir: Aktif Madenciler ({active}) + Aktif Olmayan Madenciler ({inactive})',
      inactiveTooltip:
        'Hesaplama sonuçlarını (Pay) 10 dakikadan fazla göndermeyen madenci sayısı.',
      activeTooltip:
        'Normalde hesaplama sonuçlarını (Pay) gönderen madenci sayısı',
      deadTooltip:
        '24 saatten fazla bir süredir hesaplama sonucunu (Pay) göndermeyen madencilerin sayısı',

      home: 'Ana Sayfa',
      miners: 'Madenciler',

      workerName: 'Çalışan Adı',
      Hashrate: 'Gerçek Zaman',
      Rejected: 'Reddedilme Oranı',
      dailyHashrate: 'Günlük',
      firstShare: 'İlk Bağlantı',
      LastShare: 'Son Paylaşım',
      groupName: 'Grup Adı',

      delMillTitle:
        'Bu madenci bilgilerini silmek istediğinizden emin misiniz?',
      delMillContext:
        'Bu işlem, seçtiğiniz tüm madencileri silecektir, lütfen dikkatli işlem yapın.',

      delGroupTitle: '{name} grubunu silmek istediğinizden emin misiniz?',
      delGroupContext: 'Gruptaki madencilerin tümü varsayılana taşınacak',
      delMillFailed: 'Silinemedi',

      moveTitle: 'Madencileri taşı',
      notChecked: 'Madencileri seçin ve toplu işlem yapın.',
      moveContext: 'Bu, seçilen tüm madencileri {groupName} grubuna taşıyacak.',
      moveFaild: 'Geçerli gruba taşınamıyor',

      searchPlaceholder: 'Çalışan adını girin',
      reportTime: 'Üretim zamanı',
      refresh: 'Yenile',
      editGroupName: 'Grup adını düzenle',
      workListEmpty: 'Bu grupta madenci tespit edilmedi',
      exist: 'Grup adı zaten alınmış.',

      goNewVersion: 'Yeni sürüme dön',
      goOldVersion: 'Eski sürüme dön',
      dataExport: `"{name}" Grubu madencileri hash oranı veri çıktısı`,
      exportTip:
        "Lütfen dışa aktarılacak verilerin zaman aralığını seçin ve veriler en fazla 30 gün içinde dışa aktarılabilir. Dışa aktarılan dosyanın biçimi CSV'dir.",
      exportTip2: `Mevcut hash oranının hesaplama zamanı 00:00 ile 23:59 arasıdır ve bugünkü hash oranının hesaplama zamanı 00:00 ile dışa aktarım zamanı arasındadır.`,
    },
    minerInfo: {
      title: 'Madenciler - BTC.com Havuzu',
      miners: 'Madenciler',
      hashrate: 'Tek Hash Oranı',
      runningCondition: 'Çalıştırma Koşulu',
      min: 'M',
      hour: '{n}S',
      hashrateAxis: 'Hash Oranı',
      rejected: 'Reddedildi',
      status: 'Durum',
      lastShare: 'Son Paylaşım',
      lastShareIP: "Son Paylaşım IP'si",
      hashrateChart: 'Hash Oranı Grafiği',
      realTime: 'Gerçek Zaman',
      trans: {
        seconds: 'sn',
        mins: '  dk',
        hours: ' Saat',
        ago: ' önce',
        ACTIVE: 'Aktif',
        INACTIVE: 'Etkin değil',
        DEAD: 'Ölü',
      },
    },

    earningHistory: {
      title: 'Kazanç Geçmişi - BTC.com Havuzu',
      earn: 'Maden Kazançları',
      Unpaid: 'Ödenmemiş',
      EarningsToday: 'Tahmini Kazılan',
      EarningsTodayTip:
        "Bu, 0:00(UTC)'den bu ana kadar kazılan tahmini PPS kazanç miktarını ifade eder ve bu miktar gerçek kazancınızdan farklı olabilir.",
      EarningsYesterday: 'Dünkü Kazançlar',
      Time: 'Madencilik Tarihi',
      NetworkDifficulty: 'Zorluk',
      Earnings: 'Kazanç',
      Payment: 'Ödeme Zamanı',
      payWithOTCAmount: '{otcType} ile ödeyin: {amount}',
      Mode: 'Mod',
      Address: 'Adres',
      PaidAmount: 'Kazanç',
      yesterday:
        'UTC saatinden önceki gün 0:00-24:00 arasındaki kazançları ifade eder.',
      unpaidReason: {
        PENDING_NON_ADDRESS:
          'Adresi henüz belirlemediyseniz, kazancınız bakiyenize kaydedilir',
        PENDING_CHANGED_ADDRESS:
          'Adres değiştirilirse ödeme 48 saat içinde yapılacaktır. Uyarı: Adresi kendiniz değiştirmediyseniz, hesabınız (e-posta/telefon numarası) muhtemelen saldırıya uğramıştır. Lütfen hemen başka bir e-posta veya telefon numarasıyla yeni bir hesap açın ve madencilerinizi yeni hesabınıza geçirin.',
        PENDING_NOT_ENOUGH:
          'Miktar, belirlediğiniz minimum ödemeden az (varsayılan: limit) ve bakiyenize yatırılacaktır.',
        PENDING_POOL_SETTLE_FAULT:
          'Maden havuzunun bir sonucu olarak, miktarın bu kısmı yanlışlıkla dışarı aktarılacak ve gelecekte yatırılmayacaktır.',
      },
      depositeAmount: 'Kaydedildi',
      'diff-chg': 'Diff Chg: ',
      'equals-pps': "PPS'ye eşittir",
      Chg: 'Değ.',
      status: 'Ödeme Durumu',
      paid: 'Ödendi',
      waitingPay: 'Bekliyor',
      reason: 'Neden',
      fppsMoreEarn: '{mode} Ekstra:',
      fppsContain:
        'PPS ile karşılaştırıldığında, {mode} modelinin kazancı yaklaşık {pps96} artmıştır',
      noEarningsInformation: 'Kazanç bilgisi yok',
      earningDetail: 'Kazanç Ayrıntıları',
      extraProfits: 'Ekstra kârlar',
      autoSwap: 'Otomatik takas',
      original: 'Orijinal para birimi',
      Equivalent: "BTC'nin Eşdeğeri",
      Swapped: 'Takas edildi',
      swapping: 'Takas ediliyor',
      otcEarningTip:
        "Akıllı Havuz modunun hedef para birimi kazançlarının otomatik takas yanıtları ile hesaplanması, fatura gününde Matrixport'ta otomatik takasın gerçek döviz kuruna yanıt verir.",
      originEarningTip:
        "Akıllı Havuz modunun hedef para birimi kazançlarının orijinal para birimi ile hesaplanması, fatura gününde saat 8:00'de Matrixport'taki otomatik takasın döviz kuruna göre hesaplanacaktır.",
      exportEarningsData: 'Kazanç Verilerini Dışa Aktar',
      exportTip:
        "180 güne kadar olan veriyi destekleyebilir ve dışa aktarılan dosya biçimi XLS'dir.",
      originStatusTip:
        "Kazançlar, Otomatik Takas ile hedef para birimine takas edilir. Akıllı Havuz modunun Kazanç Sayfası'nda daha fazla ayrıntıyı kontrol edebilirsiniz.",
      swappingTip:
        'Mevcut gelir takas ediliyor veya düşük gelir nedeniyle minimum takas miktarına ulaşılamıyor',
      swappedTip:
        "Kazançlar, Otomatik Takas ile hedef para birimine takas edilir. Akıllı Havuz modunun Kazanç Sayfası'nda daha fazla ayrıntıyı kontrol edebilirsiniz",
      // TODO'yu yükselt 收益改版新增
      settlementHashrate: 'Hesaplama Hash Oranı',
      addressRatio: 'Adres Oranı',
      addressEarnings: 'Adres Kazançları',
      paymentTime: 'Ödeme Zamanı',
      TimeTip:
        'Maden tarihi UTC saatindedir. Örneğin 1 Ocak 2020, UTC saatine göre 1 Ocak 2020 00:00:00 - 2 Ocak 2020 00:00:00 manasına gelir.',
      EarningsTip:
        'Kazanç miktarı, "BTC.com Hesaplama Merkezi" tarafından birden fazla faktöre bağlı olarak hesaplanır: Kazanç dağıtım yöntemi, ağ zorluğu, hesaplama hash oranı, Blok\'un temel ödülü ve Tx ücreti ödülü ve komisyon oranları vb. Kazanç hesaplayıcıda hesaplamış olduğunuz teorik kazançlarda sapmalar olabilir.',
      // upgrade TODO 邀请新增
      inviteEarning: 'Komisyon Kazançları',
      inviteWorkerAccount: 'Davetli Alt Hesabı',
      inviteRebateRatio: 'Komisyon Oranı',
      inviteRebateAmount: 'Komisyon Tutarı',
      rebateExportTip:
        "60 güne kadar veriyi destekleyebilir ve dışa aktarılan dosya formatı XLS'dir.",
    },

    //统计
    poolStats: {
      title: 'Havuz İstatistikleri - BTC.com Havuzu',
      poolStats: 'Havuz İstatistikleri',
      hashrate: 'Hash Oranı',
      miners: 'Madenciler',
      coinType: 'Coin Türü',
      found: 'Bulunan',
      hashrateChart: 'Hash Oranı Grafiği',
      blocksRelayed: 'Aktarılan Bloklar',
      height: 'Uzunluk',
      time: 'Zaman',
      blocks: 'Bloklar',
      blockHash: 'Blok Hash',
      ddaScore: 'DAA Score',
      reward: 'Ödül',
      day: 'Gün',
      region: 'Bölge',
      lucky: 'Şanslı',
      btc: 'BTC',
      nmc: 'NMC',
      zec: 'ZEC',
      bcc: 'Bitcoin Cash',
      bch: 'Bitcoin Cash',
      eth: 'Ethereum',
      dcr: 'Decred',
      etc: 'Ethereum Klasik',
      sbtc: 'Süper Bitcoin',
      ubtc: 'Birleşik Bitcoin',
      grin: 'Grin',
    },

    // 守护者
    guardian: {
      expiredDeadline: '(:gün Otomatik silindi)',
      guardianRemark:
        'Alt hesap bilgilerinizin tamamını (veya bir kısmını) (hash oranı, madenci dahil) yalnızca görüntüleyebilen, ancak herhangi bir ayarı değiştiremeyen başka bir kullanıcıyla paylaşabilirsiniz.',
      createGuardian: 'Paylaşım Kullanıcısı Ekle',
      remark: 'Açıklama:',
      user: 'Kullanıcı:',
      manage: 'Yönet',
      status: 'Durum:',
      theGuardian: 'Hisse Hesabı:',
      creationDate: 'Oluşturma tarihi:',
      bounded: 'Bağlı',
      datePrevious: 'Önceki Kullanım Tarihi:',
      delete: 'Sil',
      deleteGuardianLink: ' Hisse Hesabı Bağlantısı Sil',
      createGuardianInvitation: 'Hisse Hesabı Bağlantısı Oluştur',
      guardianAccountManagement: 'Hisse Hesabı Yönetimi',
      modifyRemark: 'Açıklamayı Değiştir',
      validateRemarkEmpty: 'Açıklama boş olamaz',
      enterRemark: 'Açıklama Ekle',
      noteInformation:
        'Not: Bu açıklama, birden fazla hisse hesabını ayırt etmek için kullanılır, iletişim bilgilerini kullanmanızı öneririz.',
      confirm: 'Onayla',
      create: 'Oluştur',
      cancel: 'İptal ',
      guardianSuccessfullyCreated:
        'Hisse hesabı davet bağlantısı başarıyla oluşturuldu',
      copyLink: 'Bağlantıyı Kopyala',
      authorized:
        'Silindikten sonra, diğer kişiler alt hesabınız için yönetim yetkisini kaybedecek.',
      noteSendGuardian:
        'İpucu: Lütfen bu bağlantıyı madenci yönetimi talebinde bulunan güvenilir kişilere gönderin. Bu bağlantı bir kez kullanılabilir, bağlantının süresi dolmuşsa lütfen yeniden oluşturun.',
      close: 'Kapat',
      subaccount: 'Alt hesap',
      coinType: 'Coin Türü',
      region: 'Bölge',
      guardianStatus: 'Paylaşım',
      guardianSelectExpress:
        'Paylaşım durumundaki geçiş vasıtasıyla, diğer hesabın alt hesabınızı yönetip yönetemeyeceğini seçebilirsiniz. (Varsayılan ayar, gardiyanın tüm alt hesaplarınızı ziyaret edebileceği şekildedir.)',
      invitTitle: 'Hesabımı Başkalarıyla Paylaş',
      acceptTitle: 'Kabul Ettiğim Başkalarının Hesabı',
      losePower: 'Eğer silerseniz, alt hesabı yönetme yetkinizi kaybedersiniz.',
      show: 'Göster',
    },
    // 绑定守护者邀请链接
    guardianBind: {
      bindingGuardianInvitation: 'Hisse Hesabı Davet Bağlantısını Bağlama',
      advance: 'Gelişmiş',
      guardianAccountManagement: 'Hisse Hesabı Bağlantısını Bağlama:',
      confirmGuardianBind: 'Hisse Hesabı Hesap Yönetimi',
      remark: 'Açıklama',
      enterRemark: 'Açıklama Ekle',
      suggestName:
        'Yönetimi daha uygun hale getirmek için bir ad kullanmanızı öneririz. Alanı boş bırakırsanız, varsayılan olarak telefon numarasını veya e-posta adresini kullanacaksınız demektir.',
      guardian: 'Bağla',
      cancel: 'İptal',
      confirm: 'Onayla',
      manageView: 'tüm alt hesaplarınızı yönetmek için？',
      suggestUsingName:
        'Yönetimi daha uygun hale getirmek için ad kullanmanızı öneririz. Alanı boş bırakırsanız, varsayılan olarak telefon numarası veya e-posta adresi kullanılacak demektir.',
      sms: 'Telefon',
      email: 'E-posta',
      code: '{mode} doğrulama Kodu',
      send: 'Gönder',
    },
    account: {
      subAccountManage: 'Alt Hesap Yönetimi',
      currentAccount: 'Mevcut Hesap',
      locationRegion:
        'Konumunuz <strong>{coin}</strong> <strong>{region}</strong> bölgesinde',
      locationEx:
        'Her alt hesap sabit bir simgeye ve alana karşılık gelir ve para birimi ve alan oluşturulduktan sonra değiştirilemez. Başka şartlar varsa, yeni bir alt hesap oluşturabilirsiniz.',
      general: 'Genel Sürüm',
      advance: 'Gelişmiş Sürüm',
      create: 'Oluştur',
      algorithm: ' Algoritma',
      oneButtonSwitch: 'Tek Tuşla Geçiş',
      addOthersCoins: 'Diğer coinleri ekle',
      supportCoins: 'Destek',
      hiddenSubAccounts: 'Gizli Alt Hesap Yönetimi',
      hashAlert: 'İyi çalışıyor',
      viewHideSubAccount: 'Alt hesabı gizle ve görüntüle',
      addSubAccount: 'Alt hesap ekle',
      recover: 'Kurtar',
      latestAlert: {
        hashrate: 'Hash oranı {symbol} {expect} {unit}',
        miners: 'Madenci sayısı {symbol} {expect}',
      },
    },
    settings: {
      subAccount: 'Alt Hesap',
      currency: 'Para Birimi',
      region: 'Bölge',
      walletAddressAndMinimumPayment: 'Cüzdan Adresi',
      walletAddress: 'Cüzdan Adresi',
      shareData: 'Hisse Hesabı',
      shareDataTip:
        'Alt hesap bilgilerinizin tamamını veya bir kısmını yalnızca görüntüleyebilen ancak değiştiremeyen kişilerle paylaşabilirsiniz; ayrıca diğer kişilerin alt hesap bilgilerinin tamamını veya bir kısmını salt okunur modda alabilirsiniz.',
      alertSettings: 'Uyarı Ayarları',
      mywatcher: 'Uyarı Ayarları',
      apikey: 'API KEY',
      payWithBtc: 'BTC ile Öde',
      //cüzdan adresi ve minimum ödeme
      setAddress: 'Lütfen bir {coinType} Adresi ayarlayın.',
      address: 'Adres',
      createAddress: 'Adres Ekle',
      modifyAddress: 'Cüzdan Adresinizi Değiştirin',
      modify: 'Değiştir',
      minPay: 'Eşik',
      lastUpdateTime: 'Adres güncelleme zamanı',
      updateBitconAddressTip:
        'Dikkat: Güvenlik nedeniyle kazançlarınız 48 saat boyunca bakiye olarak kaydedilecektir.',

      //izleyici
      old: 'Eski',
      new: 'Yeni',
      continue: 'Devam Et',
      emptyAddress: 'Adres boş olamaz',
      addAddress: 'Ayarla',
      enterCode: 'Kodu giriniz',
      newBit: 'Yeni adresiniz',
      way: 'Bu değişikliği onaylamak için size bir kod göndereceğiz, kodu nasıl almak istersiniz?',
      sendCode: 'Bu değişikliği onaylamak için size bir kod gönderdik',
      sendVerifyCode:
        'Bu değişikliği onaylamak için size {contactType} yoluyla bir kodu gönderdik.',
      sms: 'mesaj gönderildi',
      mail: 'posta gönderildi',
      mailVia: 'Doğrulama kodunu posta kutusuna gönder',
      smsVia: 'Doğrulama kodunu cep telefonuna gönder',
      errorLater: 'Lütfen daha sonra tekrar deneyin...',
      updateSuccess: 'Adres güncelleme başarılı!',
      // 多地址收款
      multAddress: {
        tag: 'Birden Fazla Adres',
        addMultAddress: 'Adres Ekle',
        adjustProportion: 'Oranı Ayarla',
        adjustMinPay: 'Eşiği Ayarla',
        address: 'Adres',
        proportion: 'Oran',
        remark: 'Not',
        lastUpdateTime: 'En Yeni Zaman',
        operation: 'İşlem',
        emptyText: 'Hiçbir adres eklemediniz',
        emptyTextForMult: 'Veri yok, 20 adrese kadar eklenebilir',
        cancel: 'İptal',
        nextStep: 'Sonraki Adım',
        delete: 'Sil',
        modify: 'Değiştir',
        newAddressPlaceholder: 'Yeni adres',
        addressMaxCountTip:
          'Şu anda, bir alt hesapta en fazla {maxCount} alıcı adresi ekleyebilirsiniz. Daha fazla adresi desteklemeniz gerekiyorsa, lütfen support@btc.com üzerinden müşteri hizmetleri ile iletişime geçin.',
        updateBitconAddressTip:
          'Fonların güvenliği için, bu işlemden sonra 48 saat içinde ödeme durdurulacak ve alınabilir kazançlarınız 48 saat sonra ilk hesaplama gününde ödenecektir.',
        emptyRemark: 'Eklenen not yok',
        noAddressForAdjust:
          'Hiç bir adres eklemediniz. Oranı veya eşiği ayarlamadan önce lütfen birkaç adres ekleyin.',
        balanceMoveTip:
          'Mevcut alt hesabın ödenmemiş kripto para birimi "Yeni Adres 1"e aktarılacak. Lütfen işleminizi onaylayın.',
        confirmModalTitle: 'Güvenlik Doğrulaması',
        authentication: 'Kimlik Doğrulaması',
        operationConfirm: 'İşlem Onayı',
        addAddressConfirmTip: '{count} adres ekliyorsunuz',
        modifyProportionConfirmTip:
          'Her bir alıcı adresin oranını ayarlıyorsunuz',
        deleteAddressConfirmTip: '<br/>{address} adresini siliyorsunuz',
        selectBalanceMoveAddress: 'Bir miras adresi seçin',
        selectBalanceMoveAddressTip:
          'Silinmekte olan adresin ödenmemiş kripto para birimi {balance}{coinType}. Bu işlemden sonra, 24 saat içinde yukarıdaki seçilen yeni adrese aktarılacaktır.',
        cannotDeleteAddress: 'Adres silinemiyor',
        deleteAddressRule:
          "Sadece ödeme oranı %0,00'a eşit olan adresler silinebilir.",
        // deleteAddressRuleDetail:
        // '1.Oran %0,00'a eşittir;<br/>2.Aynı alt hesabın akıllı madencilik adresi bu adres olarak ayarlanmamıştır.',
        // usedBySmart:
        // 'Geçerli alt hesabın akıllı madenciliği, mevcut adresi seçer ve bu nedenle bu değişiklik aynı anda makineli tüfek madenciliği adresini değiştirir.',
        operationSuccess: 'Başarılı şekilde çalışıyor',
        validate: {
          emptyAddress: 'Lütfen yeni adresi girin',
          emptyAddressForUpdate: 'Adres boş olamaz',
          emptyProportion: 'Lütfen adres oranını doldurun',
          emptyProportioAfter: ',',
          proportionInvalidate:
            "Bütün adreslerin oranlarının toplamı %100'e eşit değil",
          maxCount: 'Her alt hesap için en fazla 20 adres ayarlanabilir',
          duplicateAddressPrefix:
            'Aynı adres yalnızca bir kez eklenebilir, ancak adres',
          duplicateAddressSuffix: ' tekrar tekrar ekleniyor.',
          limitAddress: 'This type of address is not supported at this time',
        },
      },
      //min ödeme
      currentMinPay: 'Mevcut Minimum Ödeme:',
      minPayAllSubAccount: 'Minimum Ödeme, tüm BTC alt hesapları için eşittir',
      todayUpdateMinPay:
        'Madencilik kazançlarından gelen Minimum Ödeme bugünden itibaren değiştirildi: {minPay}',

      //İzleyiciyle paylaş
      watcher: 'İzleyici',
      availableWatchers: 'Mevcut İzleyiciler',
      watcherExpress:
        '* İzleyiciler verilerinizi aşağıdaki url aracılığıyla görüntüleyebilir, ama sizin hesap ayarlarınızı değiştiremezler.',
      createWatcher: 'Yeni İzleyici Oluştur',
      modifyWatcher: 'Düzenle',
      authoritySettings: 'İzin Ayarları',
      setWatcherName: 'Bu izleyici için bir ad belirleyin',
      watcherName: 'İzleyici Adı',
      watcherNameTip: '2 ~ 20 karakter',
      notEmptyWatcherNote: 'İzleyici adı boş bırakılamaz',
      tips: 'İpuçları:',
      tip_dashboard:
        '1. "Gösterge Tablosu", "Ödenmemiş" ve "Ödenmiş" gibi kazanç bilgilerini içermez;',
      tip_earning:
        '2. "Kazançlar", her adresin "Ödenmemiş" ve "Ödenmiş" gibi bilgilerini içerir.',
      moreSettings: 'Daha Fazla Ayar',
      guardianRemark:
        'Alt hesap bilgilerinizin tamamını (veya bir kısmını) (hash oranı, madenci dahil) yalnızca görüntüleyebilen ancak  herhangi bir ayarı değiştiremeyen başka bir kullanıcıyla paylaşabilirsiniz.',
      guardian: 'Hisse Hesabı',
      shareAccountsSetting: 'Hesapları Paylaşın',
      accessKey: 'Erişim anahtarı',
      generateAK: 'Erişim Anahtarı Oluştur',
      puid: 'PUID',
      apiDoc: 'API Belgesi',
      confirmDeleteWatcher: 'İzleyiciyi silinsin mi?',
      watcherAuths_Dashboard: 'Gösterge Paneli',
      watcherAuths_Dashboard_Tip: '(Kazançlar Hariç)',
      watcherAuths_Miners: 'Madenciler',
      watcherAuths_Earnings: 'Kazanç',
      watcherAuths_GetCoin: 'Hediye Parası',

      // Uyarı ayarı
      alertItem: 'Uyarı Öğesi',
      alertContacts: 'Uyarı Kişileri',
      hashrateAlert: 'Hash Hızı Uyarısı',
      minerAlert: 'Madenci Uyarısı',
      alertFrequency: 'Uyarı Sıklığı',
      alertFrequencyTip:
        'Politika sebepleri nedeniyle, bazı ülkelerde ve bölgelerde SMS uyarılarının sıklığı 24 saatte bir olarak ayarlanmıştır. Bu işbu vesile ile bildirilmektedir.',
      warnMeWhenHashrate: 'Hash oranı uyarı hattı ≤',
      warnMeWhenActiveMiner: 'Aktif madenci olduğunda beni uyar ≤',
      dontWarnMeTwice: 'Beni iki kez uyarma',
      hours: 'Saat',
      apply: 'Uygula',
      operateFailed: ' İşlem başarısız!',
      contactsList: 'Uyarı Kişileri',
      cancel: 'İptal',
      ok: 'TAMAM',
      save: 'Kaydet',
      reset: 'Sıfırla',
      newContact: 'Yeni kişi ekle',
      editContact: 'Kişiyi düzenle',
      deleteContact: 'Kişiyi sil',
      confirmDeleteContact: 'Uyarı kişisi silinsin mi?',
      name: 'İsim',
      phone: 'Telefon',
      email: 'E-posta',
      validateAlert: {
        nameEmpty: 'Ad boş olamaz',
        phoneRegionFormat: 'Geçersiz bölge kodu',
        phoneFormat: 'Geçersiz telefon numarası',
        emailFormat: 'Geçersiz e-posta',
        contactEmpty: 'Telefon olmadığında, e-posta alanı gereklidir.',
      },
      max_email_contacts: 'Maksimum 10 e-posta kişisini destekler',
      max_phone_contacts: 'En fazla 5 telefon kişisini destekler',
      enabled_alert_need_hashrate:
        'Hash gücü algılanmadı, uyarı etkinleştirilemiyor.',
      alertItemUpdateSuccess: 'Uyarı öğesi güncelleme başarısı',

      done: 'Tamamlandı',

      Okay: 'Tamam',

      invalidAddress: 'Geçersiz adres',
      emptyCode: 'Boş olamaz',
      wrongCode: 'Yanlış kod',
      emailVia: 'Bana şuradan e-posta gönder',
      textVia: 'Bana şuradan mesaj gönder',
      help: 'Yardım',

      contact: 'Kişi:',
      close: 'Kapat',
      receiveAddress: 'Adres:',

      updateAddress: 'Adres güncelleme zamanı:',
      //btc ile ödeme
      payWithBtcTip:
        "* Para birimi dönüştürme açıldıktan sonra, madencilik kârınız her gün sabit bir saatte Bitcoin'e dönüştürülür ve aşağıdaki bireysel BTC adresine ödenir. Normalde UTC saati 4:00 gerçekleşecektir ve özel durumlarda bildirilecektir.",
      convertedCurrency: 'Converted Currency: ',
      setBtcAddressTip: 'Lütfen bir BTC adresi belirleyin',
      btcAddress: 'BTC Adres: ',
      conversionRate: 'Referans Dönüşüm Oranı',
      convert: 'Dönüştür',
      getValue: 'Şunu alacaksınız',
      conversionTip:
        '* Dönüşüm oranı yalnızca referans içindir, gerçek dönüşüm sonucuna tabidir.',
      lastUpdate: 'Last updated: ',
      on: 'AÇIK',
      off: 'KAPALI',
      turnOnPaywith: 'BTC ile ödemeyi açmak istediğinizden emin misiniz?',
      turnOffPaywith: 'BTC ile ödemeyi kapatmak istediğinizden emin misiniz?',
      payOn: 'Aç',
      payOff: 'Kapat',
      resend: 'Yeniden gönder',
      send: 'Kodu gönder',
      resendTip:
        'Kodu almazsanız, lütfen bir süre bekleyin veya birkaç kez daha deneyin.',

      // 一键切换 Bir Tuşla Geçiş
      oneSwitch: {
        attention: 'Dikkat',
        optionCurrentMode: 'Lütfen modu seçin',
        switch: 'Hash oranınızı {destMode} olarak değiştirmek üzeresiniz',
        switchAnotherMining: '{destMode} madenciliğine geçiyorsunuz.',
        hashsHaveSwitched:
          "Madencilik hash'lerinin tümü {destMode} havuzuna geçti.",
        ethSwitchWarning:
          "Uyarı: Madencilik makineniz DAG'yi değiştirmeyi desteklemiyorsa, sıfır gelirle sonuçlanacaktır. Normale dönmek için madencinin manuel olarak yeniden başlatılması gerekir. (AntMiner E3'ün desteklemediği bilinmektedir. ETC'den ETH'ye geçiş için manuel yeniden başlatma gerekir).",
        ethSwitchWarningLinkTxt: 'Detaylar.',
        checkSubAccount:
          'Lütfen {subAccountName}- {subAccountRegionNode} alt hesabınızı kontrol edin.',
        enter: 'Gir',
      },
      // 机枪收款地址
      smartAddress: {
        // autoSwapSettlement: 'Otomatik Takas Hesaplama',
        original: 'Orijinal Para Birimi Hesaplama',
        autoTip:
          'Akıllı Havuz tarafından üretilen para birimleri, hedef para biriminde günlük olarak hesaplanacaktır.',
        originalTip:
          'Akıllı Havuz hesaplaması, Akıllı Havuz tarafından desteklenen BTC, BCH ve BSV içinde yapılacaktır.',
        originalCurrency: 'Orijinal Para Birimi',
        targetCurrency: 'Hedef Para Birimi',
        earningsInSmartPool: "Akıllı Havuz'daki Kazançları",
        activated: 'Aktif',
        unactivated: 'Devre dışı',
        modifyTip:
          'Akıllı Havuz otomatik takas yerleşiminin hedef para birimi, aynı alt hesabının altında aynı para biriminin cüzdan adresiyle ilişkili ve onunla tutarlıdır.',
        autoHistory:
          "Geçmiş kazançlar otomatik olarak BTC'ye değiştirilsin mi, değiştirilmesin mi?",
        notice1:
          'Güvenlik nedeniyle kazancınız 48 saat boyunca bakiye olarak kaydedilecektir.',
        notice2:
          "Akıllı Havuz'un Kazançları, Otomatik takas etkinleştirildikten sonra, hedef para birimine otomatik olarak değiştirilecektir. Tamam'ı tıkladığınızda, {fundLink} kurallarını kabul etmiş sayılacaksınız",
        notice3:
          'Akıllı moddaki adres, aynı alt hesaptaki tek coin adresiyle ilişkilendirilir ve onunla tutarlıdır (birden fazla adres ayarlanmışsa, ilk adresle tutarlı olacaktır)',
        originalNotice1:
          'Akıllı moddaki adres, aynı alt hesaptaki tek coin adresiyle ilişkilendirilir ve onunla tutarlıdır (birden fazla adres ayarlanmışsa, ilk adresle tutarlı olacaktır)',
        currentPayoutMode: 'Mevcut ödeme modu: {mode}',
        fundLink: `“BTC.com Para Birimi Otomatik Takas Lisans Sözleşmesi”`,
        originBtn: 'Orijinal para birimi hesaplamasını etkinleştir',
        otcBtn: 'Otomatik takas hesaplamasını etkinleştir',
        otcSuccess:
          'Akıllı Havuz modunun otomatik takas hesaplaması etkinleştirildi ve BTC ile ödeme yapacağız.',
        otcModalNotice2: `Otomatik takas geçmiş kazançlarını etkinleştirerek, hesaptaki önceki kazançlar takas edilecektir. O olmadan, yalnızca son kazançlar takas edilecektir.`,
        originSuccess:
          'Akıllı Havuz modunun orijinal para birimi hesaplaması etkinleştirildi ve hash oranı ile kazılan orijinal para birimi ile ödeme yapacağız.',
        // 2021-06-04新增
        mode_otc: 'Otomatik Takas Hesaplama',
        mode_origin: 'Orijinal Para Birimi Hesaplama',
        settlementMode: 'Hesaplama Modu',
        settlementModeDescription: 'Hesaplama Tanımı',
        originDescription:
          "Orijinal para birimi hesaplaması: Gerçek madencilik para biriminde hesaplama, para birimi aralığı BTC, BCH ve BSV'dir.",
        autoSwapDescription:
          "Otomatik takas hesaplaması: Önce algoritmaya göre BTC/BCH/BSV'nin dinamik madenciliğini gerçekleştirin ve ardından bunları hesaplama için BTC'ye dönüştürün.",
        settlementSwitch: 'Hesaplama Anahtarı',
        originalSettlement: 'Orijinal Para Birimi Hesaplama',
        autoSwapSettlement: 'Otomatik Takas Hesaplama',
        currency: 'Para Birimi',
        address: 'Adres',
        addressRules: 'Adres kuralları',
        switchAddress: 'Adres değiştir',
        noAddressTip: 'Aynı alt hesabın {coin} kısmına adres eklenmemiş',
        noAddressSelected: 'Adres seçilmedi',
        selectFrom: 'Aynı alt hesaba adresli BTC listesinden seçim yapın',
        settingTip:
          'Tip: "Akıllı Havuzun Adresi"ni "Aynı alt hesabın tek para biriminin adresi"nden ayırt etmek istiyorsanız, lütfen tek para birimi adres yönetiminde yeni bir adres ekleyin ve oranı %0\'a ayarlayın ve sonra buraya geri dönün ve yeni adrese geçin.',
      },
    },
    homepage: {
      btcSmartAgent: 'BTC Smart Agent',
      agentExpress: 'Verimli ve Şeffaf Tek Çalışan Görülebilir',
      bit: 'bit',
      bit64: 'Win7 veya 64 bit üzeri',
      bit32: 'WinXP 32 bit',
      document: 'Windows Yardım Belgesi.',
      accessLinuxVersion: 'Linux Sürüm İçin Erişim',
      cuttingEdgeTechnology: 'Son Teknoloji',
      latestArchitecture: 'En Yeni ve Orijinal Mimari',
      openSourceCode: 'Açık Kaynak Kodu',
      highTestPressure: 'Ultra Yüksek Stres Testi',
      lowOrphanedRate: 'Ultra Düşük Orphan Blok Oranı',
      transparentAgent: 'Şeffaf Temsilci',
      standardsServices: 'Madencilik Hizmetleri için Yeni Standartlar',
      customerHotline: 'Müşteri Yardım Hattı',
      highHashrateConsultation: 'Yüksek Hash Oranı Danışmanlığı',
      professionalCustomerService: 'Profesyonel Müşteri Hizmetleri',
      bestVipServer: 'Ultra Yüksek Özellikli VIP Sunucu',
      oneToOne: 'Bire Bir Vaka Analizi',
      appAvailable:
        'BTC.com Havuzu Uygulaması Madencileri Kolayca Yönetmeyi Destekler',
      earningsTimelyEveryday: 'Kazançları Her Gün Zamanında Alın',
      transnationalTeamOperation: 'Uluslararası Takım Operasyonu',
      currencies: 'Para Birimleri',
      dailyEarnings: 'Günlük Kazanç',
      hashrate: 'Hash Oranı',
      hashrateChart: 'Hash Oranı Grafiği',
      explorer: ' Gezgin',
      prices: 'Fiyat',
      earningsModels: 'Kazanç Modelleri',
      minPayment: 'Ödeme Eşiği',
      help: 'Yardım',
      notePaytime: 'Günlük 0:00-8:00 (UTC) dünün kazançlarını öder',
      yuanKwhElectric: 'USD/kW·h (Elektrik Fiyatı)',
      todayEarnings: 'Bugünün Kazancı:',
      piece: 'Adet',
      signIn: 'Giriş Yap',
      signUp: 'Kaydol',
      power: 'Güç',
      hashrateProfitEst: 'Hash Oranı Kâr Tah.',
      hashrateProfitEstTips:
        'There may be a deviation from your actual earnings, which is for reference only.',
      miningProfitEst: 'Madencilik Kâr Tah.',
      fee: 'Ücret',
      desc: `Dünyanın önde gelen çoklu para birimi kapsamlı hizmet madencilik havuzu`,
      tutorial: 'Öğretici',
      diff: 'Zorluk',
      nextDifficultyEstimated: 'Bir Sonraki Zorluk Tahmini',
      dateToNextDifficulty: 'Sonraki Zorluk Tarihi',
      Algorithm: 'Algoritma',
      backup: 'Yedek',
      whyMe: {
        title: "Neden BTC.com'u seçmelisiniz?",
        leadingTechnology: 'Lider Teknoloji',
        convenientManagement: 'Uygun Yönetim',
        revenueAssurance: 'Gelir Güvencesi',
        professionalServices: 'Profesyonel Hizmetler',
        desc1: `Açık kaynak kodu, madencilerin ve madencilik çiftliğinin ücretsiz yönetim yazılımı, resmî özelleştirilmiş hız aşırtma ile donanım yazılımı gelirini önemli ölçüde artırma, blockchain verileri ve teknik servis sağlayıcısı.`,
        desc2:
          'Kısa ve öz uygulama ve web sayfası işlemleri, cep telefonu numarası veya e-posta ile kolay hesap kaydı, bağımsız alarm işlevi, gelir adresi, madenci gruplaması ve veri paylaşımının izleyici bağlantısı.',
        desc3: `Her türlü durumda gerçek ve şeffaf madencilik verileri, gelişmiş FPPS gelir modeli, güçlü hesaplama merkezi ve zamanında ödeme.`,
        desc4: `BTC.com Havuzu'nun profesyonel bir müşteri hizmetleri ekibi vardır ve en yüksek özelleştirilmiş VIP hizmetleri standardına sahiptir. En iyi araştırmacılar sektördeki vakaları bire bir analiz etmektedir.`,
      },
      app: {
        title: 'Havuz Uygulaması',
        description:
          'Dünyanın önde gelen çoklu para birimli entegreli madencilik havuzu',
        property1:
          'Çoklu para birimli madenciliği, çoklu alt hesap yönetimini ve gözlemci bağlantı paylaşımının kolay yönetimi destekler.',
        property2:
          'Kapsamlı para birimi verilerinin gerçek zamanlı gösterimi, madencilik trendlerini çok daha hızlı kavrama',
        property3:
          'Daha sorunsuz kullanıcı deneyimi için yeni etkileşimli arayüz',
      },
      coinList: {
        eth: {
          earningsModelsTip: {
            FPPS: `ETH'nin hesaplama modu FPPS/PPS+ çifte modudur ve şu anki mod ise FPPS modudur. Zincir üstü işlem maliyetleri, BTC.com'un pazar payının payı, piyasa koşulları ve kullanıcının geliri gibi kapsamlı faktörlere göre bunu ayarlayacağız.`,
            'PPS+': `ETH'nin hesaplama modu FPPS/PPS+ çifte modudur ve şu anki mod ise PPS+ modudur. Zincir üstü işlem maliyetleri, BTC.com'un pazar payının payı, piyasa koşulları ve kullanıcının geliri gibi kapsamlı faktörlere göre bunu ayarlayacağız.`,
          },
        },
        ltc: {
          giftCoin: '+DOGE,BEL',
        },
      },
      globalZone: 'Global',
      zilDiff1: 'Shard',
      zilDiff2: 'DS',
      etcMinerAddressTips:
        'Yalnızca ETC madenciliği yapan ekipman bu madencilik adresine bağlanır',
      zilMinerAddressTips:
        'ETC ve ZIL ikili madencilik ekipmanı bu madencilik adresine bağlı',
    },
    advanceAccount: {
      classic: '(Klasik)',
      title: 'Gelişmiş Hesap Yönetimi - BTC.com',
      advance: 'Gelişmiş',
      all: 'Tümü',
      ofSubAccount: '  alt hesap',
      updateAt: 'Güncelleme Tarihi',
      btc: 'BTC',
      bch: 'BCH',
      sbtc: 'SBTC',
      ubtc: 'UBTC',
      dcr: 'DCR',
      ltc: 'LTC',
      grin: 'Grin',
      beam: 'BEAM',
      unit: 'Birim',
      index: 'Dizin',
      subAccount: 'Alt hesap',
      coin: 'Coinler',
      hashrate: 'Hash Oranı',
      guard: 'Not',
      allMiners: 'Tüm Madenciler',
      activeMiners: 'Aktif',
      inactiveMiners: 'Etkin değil',
      deadMiners: 'Ölü',
      region: 'Bölge',
      more: 'Daha Fazla',
      setting: 'Ayarlar',
      totalHashrate: 'Toplam Hash Oranı',
      addressChange: 'Ödeme Adresi',
      minPay: 'Min Ödeme',
      setAlert: 'Uyarı Ayarları',
      setContact: 'Uyarı Kişisi',
      nmcChange: 'NMC Ödeme Adresi',
      allIncome: 'Kazanç Geçmişi',
      shareData: 'İzleyiciyle Paylaş',
      getAk: 'AccessKey ve puid elde et',
      search: 'Arama',
      noMatch: 'Eşleşen kayıt yok',
    },
    getCoin: {
      tip: {
        btc: "Şu andan itibaren, BTC.com madencilik havuzunda Bitcoin veya Bitcoin Cash madenciliği yapıyorsanız, Namecoin(NMC), Elastos(ELA)'yı ücretsiz alabilirsiniz.",
        bch: "Şu andan itibaren, BTC.com madencilik havuzunda Bitcoin veya Bitcoin Cash madenciliği yapıyorsanız, Namecoin(NMC), Elastos(ELA)'yı ücretsiz alabilirsiniz.",
        ltc: 'Şu andan itibaren, BTC.com madencilik havuzunda Litecoin madenciliği yapıyorsanız, ücretsiz olarak Dogecoin (PPLNS) alabilirsiniz.',
        bcha: `<p>BitcoinCash(BCH) hardforku, Bitcoin Cash ağını iki yeni blok zincirine böldü, BCHA yeni bir coin'dir. Daha fazla bilgi için lütfen <a href="https://www.bitcoinabc.org/" target="__blank">https://www.bitcoinabc.org/</a></p> adresini ziyaret edin.
          <p><b>Çekme dönemi:</b><br/>2020/12/07 00:00 - 2021/01/07 00:00(UTC)</p>`,
      },
      beta: 'Kapalı betada',
      whatsThis: 'Bu nedir?',
      address: 'Adres:',
      newAddress: 'Yeni adres',
      addressComplete:
        'Kazançlarınızı korumak adına, lütfen ücretsiz coin almak için adresinizi mümkün olan en kısa sürede gönderin. ',
      bchaAddressComplete:
        "7 Ocak 2021'den sonra adres doldurmayan kullanıcılar BCHA'dan uzaklaştırılacak ve geri çekim yapamayacaklardır. Kazançlarınızı korumak adına, lütfen ücretsiz coin almak için adresinizi mümkün olan en kısa sürede gönderin.",
    },
    watcher: {
      expired: 'İzleyici yetkisinin süresi doldu',
      incorrect: 'İzleyici bağlantısı hatası',
    },
    activity: {
      pointsToday: 'Bugünkü Puanlar',
      pointsSum: 'Toplam',
      ranking: 'Sıralama',
      earnings: 'Kazanç',
    },
    minerRanking: {
      title: 'Madencilik Kâr Tahmini',
      electric: 'Yükleme/Gün',
      dailyEarning: 'Günlük Kâr',
      priceShutdown: 'Kapatma Fiyatı',
      ALL: 'Tümü',
      CNY: 'CNY',
      USD: 'USD',
      desc: 'Azalan Kâr',
      asc: 'Artan Kâr',
      electricPercent: 'Elektrik Ücret Oranı',
      searchTip: 'Madenci girin',
      updated: 'Güncelleme zamanı',
      coin: 'Coinler',
      emptyShow: '  Uygun madenciler yok',
      rank: 'Sıralama',
      miner: 'Madenci',
      power: 'Güç',
      hashrate: 'Hash Oranı',
      rev24h: 'Rev.24h',
      energy: 'Elektrik Ücreti',
      incomeWithdoge: 'Inclusive of DOGE',
      incomeWithdogeAndBel: 'Inclusive of DOGE+BEL',
    },
  },
};
const pageMessageFlatten = flatten(pageMessage);
export default pageMessageFlatten;
