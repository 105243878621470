import flatten from 'flat';
const commonMessage = {
  common: {
    locale: '简体中文',
    header: {
      home: '矿池首页',
      dashboard: '用户面板',
      getCoin: '挖矿送币',
      help: '帮助',
      miners: '矿机',
      poolStats: '统计',
      commissionEarnings: '返佣收益',
      miningEarnings: '挖矿收益',
      service: '客服',
      tools: '工具',
      setting: '设置',
      guardian: '共享',
      guardianPermission:
        '您仅享有账户数据的查看权限，但无法进行任何编辑操作。',
      watcher: '观察者',
      watcherPermission:
        '观察者享有子账户数据的查看权限，但无法进行任何编辑操作。',
      userCenter: '用户中心',
      SignOut: '退出',
      accountSetting: '账号设置',
      poolManagePanel: '矿池管理面板',
      watcherMode: '观察者模式',
      scanToService: '扫码获取帮助',
      bitdeer: 'Bitdeer专场',
      hashnest: '算力巢',
      app: 'App',
      explorer: '浏览器',
      getBCHA: '领取BCHA',
      candy: '糖果',
      alertBar:
        '<b>在8月10日 22:00 - 24:00(UTC+8)</b>， BTC.com矿池将进行大规模的数据维护，一些功能将无法使用，挖矿服务不受影响。<a href="https://help.pool.btc.com/hc/zh-cn/articles/900002124306" rel="nofollow noopener noreferrer" target="_blank">详情请点击</a>',
      introTip:
        '用户面板、矿机、收益、子账户设置等功能统一集合在「矿池管理面板」中。',
    },
    nav: {
      'nav-explorer': '浏览器',
      'nav-explorer-btc': 'Bitcoin',
      'nav-explorer-eth': 'Ethereum',
      'nav-explorer-bch': 'Bitcoin Cash',
      'nav-explorer-etc': 'Ethereum Classic',
      'nav-explorer-ltc': 'Litecoin',
      'nav-pool': '矿池服务',
      'nav-pool-home': '矿池首页',
      'nav-pool-data': '矿池数据',
      'nav-pool-candy': '糖果社区',
      'nav-pool-tools': '工具软件',
      'nav-pool-cs': '联系客服',
      'nav-wallet': '钱包',
      'nav-help': '帮助中心',
      'nav-navigation': '区块链导航',
      'nav-app': 'APP',
      'nav-inscriptions': 'Ordinals铭刻',
      'nav-collaborate': '合作专场',
      'nav-brc20': 'BRC-20',
      'nav-tools': '工具',
      'nav-tools-address-monitoring': '地址监控',
      'nav-tools-address-detail': '地址明细',
      'nav-tools-address-report': '地址报表',
      'nav-tools-address-increaseminerfee': '增加矿工费',
      'nav-tools-address-txreverse': '交易撤销',
      'nav-tools-address-doublespend': '交易双花',
      'nav-tools-APIService': 'API服务',
      'nav-tools-accelerate': '交易加速(CPFP)',
      'nav-tools-acceleratebatch': '批量加速(CPFP)',
      'nav-tools-TransactionBroadcast': '交易广播',
      'nav-tools-special': '稀有聪查询',
      'nav-tools-receipt': '交易收据',
      'nav-tools-pickupsats': '稀有聪提取',
      'nav-tools-bulkpayment': '批量付款',
      'nav-tools-address-graph': '地址图谱',
      'nav-runes': 'Runes铸造',
      'nav-runes-explorer': 'Runes浏览器',
      'nav-runes-main': 'Runes',
      tags: {
        'runes-free': '限时免费+送GOODS',
        new: 'New',
      },
    },
    menu: {},
    setting: {
      balance: '当前余额',
      coinAddress: '{coinType} 收款地址',
      setAddress: '请点击设置{coinType}收款地址',
      multAddressEnabled: '已设置为多个地址按比例收款',
      menu: {
        hide: '隐藏',
        address: '收款地址',
        minPay: '最小起付金额',
        payWithBtc: '以BTC结算',
        earingsHistory: '收益',
        shareData: '共享账户',
        watcher: '观察者设置',
        apikey: 'API KEY',
        alertSettings: '警报设置',
        onebuttonSwitch: '一键切换',
        userCenter: '用户中心',
        signOut: '退出登录',
        delete: '删除',
      },
      confirm: '确认',
      cacel: '取消',
      deleteConfirm: '删除确认',
      cannotDelete: '无法删除该子账户',
      cannotDeleteView: '不可删除当前正在查看的子账户',
      deleteCondition:
        '无法删除子账户<span class="subAccountName">{{accountMessage}}</span>,仅可删除同时满足以下两个条件的子账户：',
      cannotDeleteReason:
        '1、该子账户近180天的算力为0；<br/>2、该子账户(包括赠送币)的未支付金额为0；<br/>3、该子账号不属于平台生成的各类专属子账号。',

      deleteName: '您正在删除子账户 :',
      deleteInfo:
        '* 点击「确定」后，该子账户将被永久删除，无法找回；<br/>* 删除后，该子账户的算力曲线、收益记录等数据将无法找回。',
      deleteSuccess: '子账户删除成功',
    },
    footer: {
      services: '提供服务',
      Products: 'BTC.com产品',
      Help: '帮助中心',
      btcTool: '批量工具',
      btcSmartAgent: '智能代理',
      btcComFirmware: '定制版固件',
      blockchainExplorer: '浏览器',
      App: 'App',
      apiDocs: 'API文档',
      vipApplication: 'VIP申请',
      cloudMinePoolService: '云矿池搭建服务',
      miningTutorial: '挖矿教程',
      FAQ: '常见问题',
      Announcements: '历史公告',
      ticketSystem: '工单系统',
    },
    pageTitle: {
      home: 'BTC.com 矿池，更好的比特币矿池',
      dashboard: '用户面板 - BTC.com Pool',
      miners: '矿机管理 - BTC.com Pool',
      earningHistory: '收益历史 - BTC.com Pool',
      earningHistoryForInvite: '返佣收益 - BTC.com Pool',
      poolStats: '矿池数据 - BTC.com Pool',
      guardian: '守护者邀请链接 - BTC.com Pool',
      guardianBind: '绑定守护者邀请链接 - BTC.com Pool',
      subAccount: '创建子账户 - BTC.com Pool',
      subAccountManage: '子账户管理 - BTC.com Pool',
      settings: '设置 - BTC.com Pool',
      getCoin: '挖矿送币 - BTC.com Pool',
      tools: '工具软件 - BTC.com Pool',
      bitdeer: 'Bitdeer专场 - BTC.com Pool',
      watcherExpired: '观察者权限已过期 - BTC.com Pool',
      watcherIncorrect: '观察者链接错误 - BTC.com Pool',
      fundAuthorization: '资金兑换授权协议 - BTC.com Pool',
      app: 'App下载 - BTC.com Pool',
      invite: '邀请奖励 - BTC.com',
    },
    breadCrumbs: {
      home: '矿池首页',
      dashboard: '用户面板',
      miners: '矿机',
      earningHistory: '收益记录',
      poolStats: '矿池数据',
      shareAccount: '共享账户设置',
      guardianBindingInvitation: '绑定守护者邀请链接',
      guardianBindingLink: '绑定守护者链接',
      subAccountManage: '子账户管理 ',
      hiddenSubAccounts: '隐藏子账户管理',
      settings: '设置',
      getCoin: '挖矿送币',
      tools: '工具软件',
      fundAuthorization: '资金兑换授权协议',
    },
    currency: {
      currency: 'USD',
    },
    allSwitchMode: {
      smart_sha256: '机枪',
      otcNotice: `您即将切换算到「机枪模式」，机枪模式不支持同一币种下多地址收款，点击”确定”视为您阅读并同意{fundLink}`,
      fundLink: `《BTC.com矿池机枪池服务协议》`,
      autoTip: `使用机枪模式，将意味着把算力托管给BTC.com，矿池将会在BTC、BCH、BSV币种中以收益最优为原则进行切换。`,
      originalTip:
        '切换为原币模式，SHA256算力将单独挖所选币种，不会进行自动切换。',
    },
    users: {
      userCenter: '用户中心',
      SignOut: '退出登录',
    },
    operation: {
      name: '操作',
      success: '操作成功!',
      failed: '操作失败!',
    },
    earning: {
      balanceTip:
        'BTC.com结算中心在结算完成后，一般会在24小时内完成支付，以下几种情况除外：<br>1.未设置地址；<br>2.设置了地址但累计结算金额未达到最小起付额；<br>3.新增地址、修改地址、删除地址或修改比例等操作触发了48小时暂停打款规则；<br>4.触发了平台风控规则；<br>5.该数字货币发生硬分叉、遭遇51%攻击或其他重大升级或事故。',
    },
    meta: {
      keywords:
        '比特币,矿池,挖矿,BTC,btc.top,bsv,eth,etc,dcr,ltc,grin,beam,鱼池,slushpool,蚁池,via,viabtc,f2pool,antpool,bw,pool,蚂蚁,矿机',
      description:
        'BTC.com 矿池是比特币挖矿的全新选择，相比传统矿池，BTC.com 矿池拥有更稳定的矿池系统，更好的用户体验，更优惠的费率，更强大的配套设施。',
    },
    gotIt: '我知道了',
    foldMe: '全部折叠',
    showAll: '全部展开',
    copied: '已复制',
    more: '更多',
    none: '无',
    on: '开启',
    off: '关闭',
    all: '全部',
    modify: '修改',
    submit: '提交',
    ok: '确认',
    cancel: '取消',
    new: '新建',
    address: '地址',
    notFoundTip: '对不起，您当前访问的页面不存在',
    export: '导出',
    startDate: '开始日期',
    endDate: '结束日期',
    smartPool: '机枪',
    status: '状态',
    notice: '注意',
    yes: '是',
    no: '否',
    coin: '币种',
    custom: '自定义',
    hide: '隐藏',
    less: '收起',
    details: '详情',
    region: {
      cn: '北方联通',
      sz: '南方电信',
      us: '美国',
      eu: '欧洲',
      sg: '新加坡',
    },
    LegalStatement:
      '本人在此确认并申明：本人非古巴、伊朗、朝鲜、叙利亚、俄罗斯以及其他受到相关国家或政府或国际机构管理或执行的制裁的国家或地区居民，且本人非中国大陆地区居民。BTC.com提供的矿池服务在本人所在的国家或地区是符合法律法规和相关政策的。',
    LegalStatementConfirm:
      '由于本人所在的国家或地区使用BTC.com提供的服务系违法导致的所有法律风险和责任将完全由本人独立承担。',
  },
};
const commonMessageFlatten = flatten(commonMessage);
export default commonMessageFlatten;
