import flatten from 'flat';
const inviteMessage = {
  invite: {
    inviteTitle: '邀请奖励',
    bindPhoneTip:
      '绑定手机号即可参与有奖邀请，双向返佣，永久有效。<br>请到<span style="font-weight:500;color:#333"> 个人中心 -> 账号设置 </span>中绑定手机号。',
    needStep: '只差一步',
    createInviteAccountLoadingMessage: '正在创建您的专属邀请码和专属子账户…',
    heading:
      '邀请好友一起挖矿，拿<span style="color:#0A66C2"> 0.2% </span>的返佣奖励',
    cutoff: '受邀人佣金<span style="color:#0A66C2"> 95折 </span>',
    ruleDetails: '规则详情',
    totalRebate: '返佣总额',
    totalRebateTip:
      '返佣以原币币种结算，此处为法币估值，估值方式为以每笔应付原币乘以结算日0点(UTC+0)的汇率，然加总求和。',
    details: '详情',
    totalAccept: '总受邀人',
    minedAccept: '已挖矿受邀人',
    inviteCode: '邀请码',
    copy: '复制',
    inviteLink: '邀请链接',
    acceptInviteCode: '被邀码',
    acceptInviteCodeTip:
      '您是受邀矿工，矿池费率享受95折，您可以到 矿池管理面板 > 收益 > 收益详情中查看。',
    generatePoster: '生成海报',
    inviteRecord: '邀请记录',
    export: '导出',
    rebateRecord: '返佣记录',
    invitee: '受邀人',
    inviteeStatus: '受邀人状态',
    'inviteeStatus-unlocked': '已解除',
    'inviteeStatus-registered': '已注册',
    'inviteeStatus-mined': '已挖矿',
    registerTime: '注册时间',
    miningTime: '开始挖矿时间',
    miningTimeTip:
      '受邀请人在接收您的邀请之后第一天开始挖矿的时间，采用UTC时间。',
    coinType: '币种',
    region: '挖矿分区',
    inviteSubAccount: '返佣专属子账户',
    rebatePercent: '返佣比例',
    op: '操作',
    originCoinCount: '原币数量',
    specialSubAccountTitle:
      '我们为您生成了用于接收返佣的专属子账户，您的受邀人开始挖矿后，挖矿收益的返佣将按不同币种和不同分区结算至您相应的子账户中。',
    specialSubAccountTip:
      '默认生成BTC币种美国分区和欧洲分区的专属子账户，当您的受邀人创建了其他币种的挖矿子账户时，我们会为您生成相对应的专属子账户。',
    rebateOpAddress: '地址管理',
    rebateOpDetail: '返佣明细',
    downloadPoster: '下载海报',
    noData: '暂无数据',
    gotoBind: '去绑定',
    boundPhone: '已绑定完毕',
  },
};
const inviteMessageFlatten = flatten(inviteMessage);
export default inviteMessageFlatten;
