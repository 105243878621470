import flatten from 'flat';
const inviteMessage = {
  invite: {
    inviteTitle: '招待報酬',
    bindPhoneTip:
      '携帯電話番号をバインドして、賞品の招待状である双方向のコミッションリベートに参加します。これは永続的に有効です。<br>请到<span style="font-weight:500;color:#333"> パーソナルセンタ -> アカウント設定 </span>携帯電話番号をバインドします。',
    needStep: 'たった一歩',
    createInviteAccountLoadingMessage:
      '専用の招待コードと専用のサブアカウントを作成しています...',
    heading:
      '友達を招待して一緒に採掘し、<br><span style="color:#0A66C2"> 0.2% </span>のリベート報酬を獲得しましょう',
    cutoff:
      '招待者のコミッションが<span style="color:#0A66C2"> 5％ </span>オフ',
    ruleDetails: 'ルールの詳細',
    totalRebate: '総リベート',
    totalRebateTip:
      'コミッションは暗号通貨で決済されます。これが法定通貨の評価です。評価方法は、各支払い可能な暗号通貨に決済日の0時の為替レート（UTC + 0）を掛けて、その合計を加算する方法です。',
    details: '詳細',
    totalAccept: '総招待者',
    minedAccept: '採掘された招待者',
    inviteCode: '招待コード',
    copy: 'コピー',
    inviteLink: '招待リンク',
    acceptInviteCode: '招待コード',
    acceptInviteCodeTip:
      'あなたは招待された鉱夫であり、マイニングプール料金の5％割引をお楽しみください。 プールダッシュボード>収益>収益の詳細で確認できます。',
    generatePoster: 'ポスターを生成する',
    inviteRecord: '招待記録',
    export: '書き出す',
    rebateRecord: 'リベート記録',
    invitee: '招待された人',
    inviteeStatus: '招待者のステータス',
    'inviteeStatus-unlocked': '却下',
    'inviteeStatus-registered': '登録済み',
    'inviteeStatus-mined': '採掘',
    registerTime: '登録時間',
    miningTime: 'マイニング時間を開始します',
    miningTimeTip:
      '招待者が招待状を受け取ってから初日にマイニングを開始すると、UTC時間が採用されます。',
    coinType: '通貨',
    region: 'マイニングエリア',
    inviteSubAccount: 'サブアカウントのリベート',
    rebatePercent: '割戻し率',
    op: '操作する',
    originCoinCount: '量',
    specialSubAccountTitle:
      'コミッションリベートを受け取るための専用サブアカウントを作成しました。招待者がマイニングを開始すると、マイニング収益のコミッションリベートは、異なる通貨と異なるパーティションで対応するサブアカウントに決済されます。',
    specialSubAccountTip:
      'デフォルトでは、BTC通貨の米国とヨーロッパの排他的サブアカウントが生成されます。招待者が他の通貨のマイニングサブアカウントを作成すると、対応する排他的サブアカウントが生成されます。',
    rebateOpAddress: 'アドレス管理',
    rebateOpDetail: 'リベートの詳細',
    downloadPoster: 'ダウンロード',
    noData: 'データなし',
    gotoBind: 'バインドする',
    boundPhone: '終わり',
  },
};
const inviteMessageFlatten = flatten(inviteMessage);
export default inviteMessageFlatten;
