import flatten from 'flat';
const commonMessage = {
  common: {
    locale: 'Китайский (упрощенный)',
    header: {
      home: 'Дом с бассейном',
      dashboard: 'Панель',
      getCoin: 'Подарочная монета',
      help: 'Справка',
      miners: 'Майнер',
      poolStats: 'Статистика',
      commissionEarnings: 'Комиссионные доходы',
      miningEarnings: 'Прибыль от майнинга',
      service: 'Служба поддержки',
      tools: 'Инструменты',
      setting: 'Настройка',
      guardian: 'Поделиться',
      guardianPermission:
        'Вы можете только просматривать данные аккаунта без возможности редактирования。',
      watcher: 'наблюдатели',
      watcherPermission:
        'Наблюдатель может только просматривать данные субаккаунта без возможности。',
      userCenter: 'редактирования',
      SignOut: 'Выйти',
      accountSetting: 'Настройки аккаунта',
      poolManagePanel: 'Dashboard',
      watcherMode: 'Режим наблюдателя',
      scanToService: 'Отсканируйте для получения справки',
      bitdeer: 'Bitdeerзона',
      hashnest: 'HashNest',
      app: 'App',
      explorer: 'Браузер',
      getBCHA: 'ПолучитьBCHA',
      candy: 'Конфеты',
      alertBar:
        '<b>在8月10日 22:00 - 24:00(UTC+8)</b>， BTC.comВ майненг-пуле будет проведено масштабное обслуживание данных. Некоторые функции могут быть недоступны, но майнинг будет работать в обычном режиме。<a href="https://help.pool.btc.com/hc/zh-cn/articles/900002124306" rel="nofollow noopener noreferrer" target="_blank">Нажмите, чтобы узнать подробнее</a>',
      introTip:
        'Такие функции, как панель инструментов, майнеры, доходы и настройки дополнительной учетной записи, объединены в "Приборная панель".',
    },
    nav: {
      'nav-explorer': 'Браузер',
      'nav-explorer-btc': 'Bitcoin',
      'nav-explorer-eth': 'Ethereum',
      'nav-explorer-bch': 'Bitcoin Cash',
      'nav-explorer-etc': 'Ethereum Classic',
      'nav-explorer-ltc': 'Litecoin',
      'nav-pool': 'Бассейн',
      'nav-pool-home': 'Дом с бассейном',
      'nav-pool-data': 'Данные пула',
      'nav-pool-candy': 'Конфеты',
      'nav-pool-tools': 'Инструменты',
      'nav-pool-cs': 'Обслуживание клиентов',
      'nav-wallet': 'Кошелек',
      'nav-help': 'Помощь',
      'nav-navigation': 'Blockchain Gezintisi',
      'nav-inscriptions': 'Вписать',
      'nav-app': 'APP',
      'nav-collaborate': 'COOP',
      'nav-brc20': 'BRC-20',
      'nav-tools': 'Инструмент',
      'nav-tools-address-monitoring': 'Address Monitoring',
      'nav-tools-address-detail': 'Address Txn Details',
      'nav-tools-address-report': 'Address Report',
      'nav-tools-address-increaseminerfee': 'Increase Txn Fees',
      'nav-tools-address-txreverse': 'Transaction Reversal',
      'nav-tools-address-doublespend': 'Txn Double-spending',
      'nav-tools-APIService': 'API Service',
      'nav-tools-accelerate': 'Txn Accelerator(CPFP)',
      'nav-tools-acceleratebatch': 'Batch Txns Accelerator',
      'nav-tools-TransactionBroadcast': 'Transaction Broadcast',
      'nav-tools-special': 'Rare Sats Query',
      'nav-tools-receipt': 'Transaction Receipt',
      'nav-tools-pickupsats': 'Transfer Rare Sats',
      'nav-tools-bulkpayment': 'Batch Payment',
      'nav-tools-address-graph': 'Address Graph',
      'nav-runes': 'Runes Mint',
      'nav-runes-explorer': 'Runes Explorer',
      'nav-runes-main': 'Runes',
      tags: {
        'runes-free': '0 Fees and Giveaway GOODS',
        new: 'New',
      },
    },
    menu: {},
    setting: {
      balance: 'Текущий баланс',
      coinAddress: '{coinType} Адрес получения',
      setAddress: 'Нажмите, чтобы настроить{coinType}адрес получения',
      multAddressEnabled: 'Включена многоадресная оплата',
      menu: {
        hide: 'Скрыть',
        address: 'Изменить адрес получения',
        minPay: 'Мин. сумма оплаты',
        payWithBtc: 'Рассчитывается BTC',
        earingsHistory: 'Доход',
        shareData: 'Поделиться аккаунтом',
        watcher: 'Настройки Наблюдателя',
        apikey: 'API KEY',
        alertSettings: 'Настройки уведомленийs',
        onebuttonSwitch: 'Выключатель',
        userCenter: 'Центр пользователя',
        signOut: 'Выйти',
        delete: 'Удалить',
      },
      confirm: 'хорошо',
      cacel: 'Удалить',
      deleteConfirm: 'Удалить подтверждение',
      cannotDelete: 'Невозможно удалить субаккаунт',
      cannotDeleteView:
        'Невозможно удалить субаккаунт, который просматривается в данный момент',
      deleteCondition:
        'Невозможно удалить субаккаунт<span class="subAccountName">{{accountMessage}}</span>,Могут быть удалены только те субаккаунты, которые соответствуют следующим двум условиям:',
      cannotDeleteReason:
        '1、Хешрейт субсчета за последние 180 дней равен 0;<br/>2、Неоплаченная криптовалюта субаккаунта (включая подарочные монеты) - 0;<br/>3、Субсчет не относится к каким-либо специальным субсчетам.',

      deleteName: 'Вы удаляете дополнительную учетную запись :',
      deleteInfo:
        '* После нажатия "ОК" субаккаунт будет окончательно удален и не может быть восстановлен;<br/>* После удаления кривая хешрейта, запись о доходе и другие данные субсчета не могут быть восстановлены.',
      deleteSuccess: 'Успешно удалено',
    },
    footer: {
      services: 'Сервисы',
      Products: 'BTC.comИзделие',
      Help: 'Справочный центр',
      btcTool: 'Инструменты партии',
      btcSmartAgent: 'Интеллектуальный агентский сервис',
      btcComFirmware: 'Персонализированные программы',
      blockchainExplorer: 'Браузер',
      App: 'App',
      apiDocs: 'APIДокумент',
      vipApplication: 'VIPПодать заявку',
      cloudMinePoolService: 'Сервис облачного майнинг-пула',
      miningTutorial: 'Курс обучения майнингу',
      FAQ: 'FAQ',
      Announcements: 'История объявлений',
      ticketSystem: 'Система заказов',
    },
    pageTitle: {
      home: 'BTC.com майнинг-пул — более качественный майнинг-пул биткоинов',
      dashboard: 'Панель - BTC.com Pool',
      miners: 'Управление майнером  - BTC.com Pool',
      earningHistory: 'История доходов - BTC.com Pool',
      earningHistoryForInvite: 'Комиссионные доходы - BTC.com Pool',
      poolStats: 'Данные пула - BTC.com Pool',
      guardian: 'Ссылка приглашения хранителя - BTC.com Pool',
      guardianBind: 'Ссылка приглашения хранителя для привязки - BTC.com Pool',
      subAccount: 'Создать субаккаунт - BTC.com Pool',
      subAccountManage: 'Управление субаккаунтом - BTC.com Pool',
      settings: 'Настройки - BTC.com Pool',
      getCoin: 'Подарочная монета - BTC.com Pool',
      tools: 'Инструменты - BTC.com Pool',
      bitdeer: 'Bitdeerзона - BTC.com Pool',
      watcherExpired: 'Срок действия прав наблюдателя истек- BTC.com Pool',
      watcherIncorrect: 'Ошибка ссылки наблюдателя- BTC.com Pool',
      fundAuthorization:
        'Соглашение о праве обмена финансовых средств BTC.com Pool',
      app: 'AppЗагрузить - BTC.com Pool',
      invite: 'Invitation Reward - BTC.com',
    },
    breadCrumbs: {
      home: 'Главная',
      dashboard: 'Панель',
      miners: 'Майнер',
      earningHistory: 'Запись дохода',
      poolStats: 'Данные пула',
      shareAccount: 'Настройка совместного использования аккаунта',
      guardianBindingInvitation: 'Ссылка приглашения хранителя для привязки',
      guardianBindingLink: 'Ссылка привязки хранителя',
      subAccountManage: 'Управление субаккаунтом ',
      hiddenSubAccounts: 'Скрыть управление субаккаунтом',
      settings: 'Настройка',
      getCoin: 'Подарочная монета',
      tools: 'Инструменты',
      fundAuthorization: 'Соглашение о праве обмена финансовых средств',
    },
    currency: {
      currency: 'USD',
    },
    allSwitchMode: {
      smart_sha256: 'умный бассейн',
      otcNotice: `Вычислительная мощность будет изменена на  орудие. Нажимая Подтверждаю, Вы даете свое согласие{fundLink}`,
      fundLink: `《BTC.comСоглашение о предоставлении сервиса майнинг-пула и пула орудий》`,
      autoTip: `При использовании режима умный бассейн Вы передаете вычислительную мощностьBTC.com，Майнинг-пулBTC、BCH、BSVсреди валют переключится на наиболее выгодную。`,
      originalTip:
        'Переключиться на исходную валюту，SHA256Вычислительная мощность будет использоваться для раздельного майнинга выбранной валюты. Автопереключение недоступно.。',
    },
    users: {
      userCenter: 'Центр пользователя',
      SignOut: 'Выйти',
    },
    operation: {
      name: 'Операция',
      success: 'Операция выполнена!',
      failed: 'Сбой операции!',
    },
    earning: {
      balanceTip:
        'После завершения расчета мы обычно завершаем платеж в течение 24 часов, за исключением следующих ситуаций:<br>1.Адрес не задан;<br>2.Адрес указан, но совокупная сумма расчета не достигла минимального порога оплаты;<br>3.Такие операции, как добавление адреса, изменение адреса, удаление адреса или изменение соотношения, сработали для правила 48-часовой приостановки платежа;<br>4.Сработало правило контроля риска;<br>5.Валюта подвергается хард-форку, атаке 51%, другим серьезным улучшениям или авариям.',
    },
    meta: {
      keywords:
        'биткоины, майнинг-пулы, майнинг,BTC,btc.top,bsv,eth,etc,dcr,ltc,grin,beam,鱼池,slushpool,蚁池,via,viabtc,f2pool,antpool,bw,pool,муравей, майнер',
      description:
        'BTC.com майнинг-пул — новейшее решение в майнинге биткоинов. По сравнению с традиционными майнинг-пулами，BTC.com майнинг-пул имеет более стабильную систему, обеспечивает более высокое качество работы пользователей, более выгодные тарифы и более мощное оборудование。',
    },
    gotIt: 'Понял',
    foldMe: 'Свернуть все',
    showAll: 'Показать все',
    copied: 'Скопировано',
    more: 'Еще',
    none: 'Нет',
    on: 'Включить',
    off: 'Закрыть',
    all: 'Все',
    modify: 'Изменить',
    submit: 'Отправить',
    ok: 'OK',
    cancel: 'Отмена',
    new: 'Создать',
    address: 'Адрес',
    notFoundTip: 'К сожалению, такая страница не существует',
    export: 'Экспорт',
    startDate: 'Время начала',
    endDate: 'Время окончания',
    smartPool: 'умный бассейн',
    status: 'Статус',
    notice: 'Внимание',
    yes: 'Да',
    no: 'Нет',
    coin: 'Тип валюты',
    custom: 'Пользовательский',
    hide: 'Скрыть',
    less: 'Скрыть',
    details: 'Подробности',
    region: {
      cn: 'Beijing(China)',
      sz: 'Shenzhen(China)',
      us: 'US',
      eu: 'EU',
      sg: 'Singapore',
    },
    LegalStatement:
      'Настоящим подтверждаю и подтверждаю: я не являюсь резидентом Кубы, Ирана, Северной Кореи, Сирии , Россия или других стран или регионов, подпадающих под санкции, вводимые или применяемые соответствующими странами, правительствами или международными организациями, и я не являюсь резидентом материкового Китая. Сервис майнингового пула, предоставляемый BTC.com,соответствует законам, постановлениям и соответствующим политикам страны или региона, где я нахожусь.',
    LegalStatementConfirm:
      'Все юридические риски и обязательства, вызванные незаконным использованием услуг, предоставляемых BTC.com в стране или регионе, где я живу, полностью ложусь на меня.',
  },
};
const commonMessageFlatten = flatten(commonMessage);
export default commonMessageFlatten;
