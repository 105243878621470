import flatten from 'flat';
const inviteMessage = {
  invite: {
    inviteTitle: 'Приглашение',
    bindPhoneTip:
      'Привязать номер телефона для участия, обе стороны получают комиссию, действует бессрочно.<br>Пожалуйста, зайдите в<span style="font-weight:500;color:#333">  Центр пользователя  -> Настройки учетной записи </span>привязать свой номер телефона.',
    needStep: 'Еще один шаг',
    createInviteAccountLoadingMessage:
      'Создание кода и дополнительной учетной записи ...',
    heading:
      'Пригласите друзей сыграть <br>вместе и получите скидку <span style="color:#0A66C2">0,2%</span>',
    cutoff:
      '<span style="color:#0A66C2">5% </span>скидка на комиссию для приглашенных',
    ruleDetails: 'Детали правила',
    totalRebate: 'Общая комиссия',
    totalRebateTip:
      'Комиссия рассчитывается в криптовалюте, это оценка в фиатной валюте, метод оценки заключается в умножении каждой подлежащей оплате криптовалюты на обменный курс 0 часов (UTC + 0) на дату расчета, а затем добавление суммы.',
    details: 'Подробности',
    totalAccept: 'Приглашенные',
    minedAccept: 'Приглашенные в майнинг',
    inviteCode: 'Приглашенные в майнинг',
    copy: 'Копировать',
    inviteLink: 'Пригласительная ссылка',
    acceptInviteCode: 'Приглашенный код',
    acceptInviteCodeTip:
      'Вы приглашенный майнер и получаете 5% скидку на ставку майнинг-пула. Вы можете проверить это на панели управления пулом> Доходы> Сведения о доходах.',
    generatePoster: 'Создать плакат',
    inviteRecord: 'Запись приглашения',
    export: 'Экспорт',
    rebateRecord: 'Отчет о комиссии',
    invitee: 'Приглашенный',
    inviteeStatus: 'Положение дел',
    'inviteeStatus-unlocked': 'Отклонено',
    'inviteeStatus-registered': 'Зарегистрировано',
    'inviteeStatus-mined': 'Добытый',
    registerTime: 'Приглашенное время',
    miningTime: 'Начать майнинг',
    miningTimeTip:
      'Когда приглашенный начинает майнинг в первый день после получения вашего приглашения, принимается время в формате UTC.',
    coinType: 'Валюта',
    region: 'Горный район',
    inviteSubAccount: 'Комиссионный субсчет',
    rebatePercent: 'Ставка комиссии',
    op: 'Работать',
    originCoinCount: 'Количество',
    specialSubAccountTitle:
      'Мы создали несколько специальных субсчетов, чтобы вы могли получать комиссионные вознаграждения. После того, как ваши приглашенные начнут майнить, комиссия за майнинг будет выплачиваться на ваши соответствующие субсчета в разных валютах и ​​в разных регионах.',
    specialSubAccountTip:
      'По умолчанию создаются комиссионные субсчета для валюты BTC в США и Европе. Когда ваш приглашенный создает дополнительную учетную запись для майнинга для других валют, мы сгенерируем для вас соответствующую специальную дополнительную учетную запись.',
    rebateOpAddress: 'Управление адресами',
    rebateOpDetail: 'Детали комиссии',
    downloadPoster: 'Скачать',
    noData: 'Нет данных',
    gotoBind: 'Связывать',
    boundPhone: 'Я связал это',
  },
};
const inviteMessageFlatten = flatten(inviteMessage);
export default inviteMessageFlatten;
