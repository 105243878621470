import { merge } from 'lodash';
// import { helpBaseURL } from '../constants';
const helpBaseURL = process.env.REACT_APP_HELP_CENTER_DOMAIN;
const mainDomain = process.env.REACT_APP_MAIN_DOMAIN;
const oldVerBsvExplorer = process.env.REACT_APP_OLD_VER_BSV_EXPLORER;
const reactAppExplorerUrl = process.env.REACT_APP_EXPLORER_URL;
/**
 * 币种相关配置
 *
 * 浏览器地址：explorer
 * 块高度：blockHeight、blockHash
 * 地址：address
 * 交易：txHash
 * 算力量级单位：hashrateScale
 * 算力单位：hashrateUnit
 * 全名：fullName
 * 中文名：zhName
 * 价格单位：priceUnit
 * 算法：algorithm
 * zendesk帮助链接：zendeskLink
 * 挖矿计算机：miningCalculator
 * 对于blockHeight、blockHash、address、txHash
 * 1. 以'/'开头会合并上explorer
 * 2. 删除对应配置或者值为null，最后会返回''（如grin的txHash）
 * 3. 对于blockHeight\blockHash 如果没有配置会取对应的blockHash\blockHeight配置
 */
const coinsConfig = {
  btc: {
    symbol: 'BTC',
    fullName: 'Bitcoin',
    zhName: '比特币',
    priceUnit: 'BTC',
    algorithm: 'SHA256',
    hashrateScale: 'T',
    biggestHashrateScale: 'P',
    hashrateUnit: 'H/s',
    explorer: mainDomain,
    blockHeight: '/btc/block/',
    blockHash: '/btc/block/',
    address: '/btc/address/',
    txHash: '/btc/transaction/',
    showMergeMining: true,
    showSwitch: true,
    zendeskLink: `${helpBaseURL}/hc/{zenLang}/articles/360020217972`,
    miningGuidLink: `${helpBaseURL}/hc/{zenLang}/articles/360038354832`,
    miningCalculator: `${mainDomain}/tools/mining-calculator`,
    //是否隐藏预估挖矿数值
    isHideEstimatedMined: false,
    isHideEstimatedMinedAddrees: true,
    // dashboard 页面配置
    dashboard: {
      // 网络状态
      networkStatus: {
        // 显示哪些币种收益
        showHashrateIncomeCoins: ['btc', 'bch'],
        // 是否显示下次难度
        isShowNextDiff: true,
      },
    },
    showNextDiff: true,
    showExplorer: true,
  },
  bch: {
    symbol: 'BCH',
    fullName: 'Bitcoin Cash',
    zhName: '比特币现金',
    priceUnit: 'BCH',
    algorithm: 'SHA256',
    hashrateScale: 'T',
    biggestHashrateScale: 'P',
    hashrateUnit: 'H/s',
    explorer: `${mainDomain}/bch`,
    blockHeight: '/block/',
    blockHash: '/block/',
    address: '/address/',
    txHash: '/transaction/',
    showMergeMining: true,
    showSwitch: true,
    zendeskLink: `${helpBaseURL}/hc/{zenLang}/articles/360020263272`,
    miningGuidLink: `${helpBaseURL}/hc/{zenLang}/articles/360038825231`,
    miningCalculator: `${mainDomain}/tools/mining-calculator`,
    dashboard: {
      // 网络状态
      networkStatus: {
        // 显示哪些币种收益
        showHashrateIncomeCoins: ['bch', 'btc'],
        // 是否显示下次难度
        isShowNextDiff: false,
      },
    },
    showExplorer: true,
  },
  bsv: {
    symbol: 'BSV',
    fullName: 'Bitcoin SV',
    zhName: 'Bitcoin SV',
    priceUnit: 'BSV',
    algorithm: 'SHA256',
    hashrateScale: 'T',
    biggestHashrateScale: 'P',
    hashrateUnit: 'H/s',
    explorer: '{oldVerBsvExplorer}',
    blockHeight: '/',
    blockHash: '/',
    address: '/',
    txHash: '/',
    showMergeMining: false,
    showSwitch: true,
    zendeskLink: `${helpBaseURL}/hc/{zenLang}/articles/360029465351`,
  },
  eth: {
    symbol: 'ETH',
    fullName: 'Ethereum',
    zhName: '以太坊',
    priceUnit: 'ETH',
    algorithm: 'ETHASH',
    hashrateScale: 'M',
    biggestHashrateScale: 'P',
    hashrateUnit: 'H/s',
    explorer: `${mainDomain}/eth`,
    blockHeight: '/block/',
    address: '/address/',
    txHash: '/transaction/',
    showSwitch: true,
    zendeskLink: `${helpBaseURL}/hc/{zenLang}/articles/360020218012`,
    miningGuidLink: `${helpBaseURL}/hc/{zenLang}/articles/360038739151`,
    showSwitchTip: true,
    coinHidden: true,
    dashboard: {
      // 网络状态
      networkStatus: {
        // 显示哪些币种收益
        showHashrateIncomeCoins: ['eth', 'etc'],
        // 是否显示下次难度
        isShowNextDiff: false,
      },
    },
    showExplorer: true,
  },
  etc: {
    symbol: 'ETC',
    fullName: 'Ethereum Classic',
    zhName: '以太坊经典',
    priceUnit: 'ETC',
    algorithm: 'ETCHASH',
    hashrateScale: 'T',
    biggestHashrateScale: 'P',
    hashrateUnit: 'H/s',
    icon: '/static/images/coin/icon_etc_zil@2x.png',
    origin_icon: '/static/images/coin/icon_etc@2x.png',
    explorer: `${reactAppExplorerUrl}/etc`,
    blockHeight: '/block/',
    blockHash: '/block/',
    address: '/address/',
    txHash: '/tx/',
    showSwitch: true,
    zendeskLink: `${helpBaseURL}/hc/{zenLang}/articles/360020796391`,
    miningGuidLink: `${helpBaseURL}/hc/{zenLang}/articles/900000852383`,
    showSwitchTip: true,
    //是否隐藏预估挖矿数值
    isHideEstimatedMined: true,
    //是否启用全球区
    isGlobalZone: true,
    dashboard: {
      // 网络状态
      networkStatus: {
        // 显示哪些币种收益
        showHashrateIncomeCoins: ['ethf'],
        // 是否显示下次难度
        isShowNextDiff: false,
      },
    },
    showExplorer: true,
    secondCoin: 'ZIL',
    showName: 'ETC/ETC+ZIL',
    coinTab: ['ETC', 'ZIL'],
    secondCoinNodeId: '10001',
    showSecondCoinName: 'ETC+ZIL',
    showCoinTypeInTips: true,
    doubleCoinName: 'ETCZIL',
    showSecondCoinMiningGuidLink: true,
  },
  zil: {
    symbol: 'ZIL',
    priceUnit: 'ZIL',
    algorithm: 'ETHASH',
    hashrateUnit: 'H/s',
    miningGuidLink: `${helpBaseURL}/hc/{zenLang}/articles/17487951124121`,
    //是否隐藏预估挖矿数值
    isHideEstimatedMined: true,
    showSecondCoinName: 'ETC+ZIL',
    isOnlyCheckDefaultIncome: true,
    coinHidden: true,
    coinTotalHidden: true,
    showNextDiff: false,
    showDiff1: 'Diff1',
    showDiff2: 'Diff2',
    icon: '/static/images/coin/icon_zil@2x.png',
    regionId: 73,
  },
  dcr: {
    symbol: 'DCR',
    fullName: 'Decred',
    priceUnit: 'DCR',
    algorithm: 'BLAKE256R14',
    hashrateScale: 'T',
    biggestHashrateScale: 'P',
    hashrateUnit: 'H/s',
    explorer: 'https://mainnet.decred.org',
    blockHash: '/block/',
    address: '/address/',
    txHash: '/tx/',
    zendeskLink: `${helpBaseURL}/hc/{zenLang}/articles/360020263292`,
    miningGuidLink: `${helpBaseURL}/hc/{zenLang}/articles/360038396152`,
    dashboard: {
      // 网络状态
      networkStatus: {
        // 显示哪些币种收益
        showHashrateIncomeCoins: ['ubtc'],
        // 是否显示下次难度
        isShowNextDiff: true,
      },
      // 挖矿地址
      miningAddress: {
        // 是否显示nicehash地址
        isShowNicehashAddress: true,
      },
    },
    showNextDiff: true,
    coinHidden: true,
    blockHeightUseHashLink: true,
  },
  ltc: {
    symbol: 'LTC',
    fullName: 'Litecoin',
    zhName: '莱特币',
    priceUnit: 'LTC',
    algorithm: 'Scrypt',
    hashrateScale: 'M',
    biggestHashrateScale: 'P',
    hashrateUnit: 'H/s',
    explorer: `${mainDomain}/ltc`,
    blockHeight: '/block/',
    blockHash: '/block/',
    address: '/address/',
    txHash: '/transaction/',
    showMergeMining: true,
    zendeskLink: `${helpBaseURL}/hc/{zenLang}/articles/360020480372`,
    miningGuidLink: `${helpBaseURL}/hc/{zenLang}/articles/360038356552`,
    dashboard: {
      // 网络状态
      networkStatus: {
        // 显示哪些币种收益
        showHashrateIncomeCoins: ['ltc'],
        // 是否显示下次难度
        isShowNextDiff: true,
      },
    },
    showNextDiff: true,
    showExplorer: true,
    showGiftCoin: true,
    giftCoin: 'DOGE',
    blockHeightUseHashLink: true,
  },
  doge: {
    symbol: 'DOGE',
    fullName: 'Dogecoin',
    zhName: '狗狗币',
    priceUnit: 'DOGE',
    algorithm: 'Scrypt',
    hashrateScale: 'M',
    biggestHashrateScale: 'P',
    hashrateUnit: 'H/s',
    explorer: 'https://dogechain.info',
    blockHeight: '/block/',
    blockHash: '/block/',
    address: '/address/',
    txHash: '/tx/',
    icon: '',
    zendeskLink: `${helpBaseURL}/hc/{zenLang}/articles/360026462972`,
  },
  grin: {
    symbol: 'GRIN',
    fullName: 'Grin-c29',
    priceUnit: 'GRIN',
    algorithm: 'Cuckoo Cycle',
    hashrateScale: '',
    biggestHashrateScale: 'M',
    hashrateUnit: 'g/s',
    icon: '/static/images/coin/icon_grin@2x.png',
    explorer: 'https://grinexplorer.net',
    blockHeight: '/block/',
    blockHash: '/block/',
    address: '',
    zendeskLink: `${helpBaseURL}/hc/{zenLang}/articles/360021371292`,
    isOffLine: true, // 是否下线
  },
  dash: {
    symbol: 'DASH',
    fullName: 'Dash',
    zhName: '达世币',
    priceUnit: 'DASH',
    algorithm: 'X11',
    hashrateScale: 'G',
    biggestHashrateScale: 'P',
    hashrateUnit: 'H/s',
    icon: '/static/images/coin/icon_dash@2x.png',
    explorer: 'https://blockchair.com',
    blockHeight: '/dash/block/',
    blockHash: '/dash/block/',
    address: '/dash/address/',
    txHash: '/dash/transaction/',
    zendeskLink: '',
    miningCalculator: 'http://www.wabi.com/mining-income/dash-calculator.html',
  },
  zec: {
    symbol: 'ZEC',
    fullName: 'Zcash',
    zhName: 'Zcash',
    priceUnit: 'ZEC',
    algorithm: 'Equihash',
    hashrateScale: 'K',
    icon: '/static/images/coin/icon_zec@2x.png',
    biggestHashrateScale: 'G',
    hashrateUnit: 'Sol/s',
    explorer: '',
    blockHeight: '',
    blockHash: '',
    address: '',
    txHash: '',
    zendeskLink: '',
  },
  nmc: {
    symbol: 'NMC',
    fullName: 'Namecoin',
    zhName: '域名币',
    priceUnit: 'NMC',
    algorithm: 'SHA256',
    hashrateScale: 'T',
    biggestHashrateScale: 'P',
    hashrateUnit: 'H/s',
    explorer: 'https://bchain.info/NMC',
    blockHeight: '/block/',
    blockHash: '/block/',
    address: '/addr/',
    txHash: '/tx/',
    icon: '',
    zendeskLink: `${helpBaseURL}/hc/{zenLang}/articles/360020805651`,
  },
  ela: {
    symbol: 'ELA',
    fullName: 'Elastos',
    zhName: '亦来云',
    priceUnit: 'ELA',
    algorithm: 'SHA256',
    hashrateScale: '',
    biggestHashrateScale: '',
    hashrateUnit: 'H/s',
    address: '',
    icon: '',
    zendeskLink: `${helpBaseURL}/hc/{zenLang}/articles/360026754171`,
  },
  ckb: {
    symbol: 'CKB',
    fullName: 'Nervos',
    zhName: 'Nervos',
    priceUnit: 'CKB',
    algorithm: 'Eaglesong',
    hashrateScale: 'M',
    biggestHashrateScale: 'P',
    hashrateUnit: 'H/s',
    explorer: 'https://explorer.nervos.org',
    blockHeight: '/block/',
    blockHash: '/block/',
    address: '/address/',
    txHash: '/transaction/',
    zendeskLink: `${helpBaseURL}/hc/{zenLang}/articles/360034146232`,
    miningGuidLink: `${helpBaseURL}/hc/{zenLang}/articles/360038783551`,
    coinHidden: true,
  },
  ethf: {
    symbol: 'ETHF',
    fullName: 'EthereumFair',
    zhName: '以太坊Fair',
    priceUnit: 'ETHF',
    algorithm: 'ETHASH',
    hashrateScale: 'G',
    biggestHashrateScale: 'P',
    hashrateUnit: 'H/s',
    explorer: 'https://www.oklink.com/en/ethf',
    blockHeight: '/block/',
    blockHash: '/block/',
    address: '/addr/',
    txHash: '/tx/',
    showSwitch: true,
    zendeskLink: `${helpBaseURL}/hc/{zenLang}/articles/10905424257433`,
    miningGuidLink: `${helpBaseURL}/hc/{zenLang}/articles/10905911490329`,
    showExplorer: false,
    coinHidden: true,
  },
  ethw: {
    symbol: 'ETHW',
    fullName: 'EthereumPoW',
    zhName: '以太坊PoW',
    priceUnit: 'ETHW',
    algorithm: 'ETHASH',
    hashrateScale: 'T',
    biggestHashrateScale: 'P',
    hashrateUnit: 'H/s',
    explorer: 'https://ethw.tokenview.io/en',
    blockHeight: '/block/',
    blockHash: '/block/',
    address: '/addr/',
    txHash: '/tx/',
    showSwitch: true,
    zendeskLink: `${helpBaseURL}/hc/{zenLang}/articles/360020796391`,
    showExplorer: false,
  },
  kas: {
    symbol: 'KAS',
    fullName: 'Kaspa',
    zhName: 'Kaspa',
    priceUnit: 'KAS',
    algorithm: 'kHeavyHash',
    hashrateScale: 'T',
    biggestHashrateScale: 'P',
    hashrateUnit: 'H/s',
    icon: '/static/images/coin/icon_kas.png',
    origin_icon: '/static/images/coin/icon_kas.png',
    explorer: `https://kas.fyi`,
    blockHeight: '/block/',
    blockHash: '/block/',
    address: '/address/',
    txHash: '/transaction/',
    showSwitch: true,
    zendeskLink: `${helpBaseURL}/hc/{zenLang}/articles/35420493733657`,
    miningGuidLink: `${helpBaseURL}/hc/{zenLang}/articles/35436231634713`,
    showSwitchTip: true,
    //是否隐藏预估挖矿数值
    isHideEstimatedMined: true,
    //是否启用全球区
    isGlobalZone: false,
    dashboard: {
      // 网络状态
      networkStatus: {
        // 是否显示下次难度
        isShowNextDiff: false,
      },
    },
    showExplorer: false,
    blockHeightUseHashLink: true,
    heightReplaceDDA: true,
    earnHistoryHideMoreThanPpsRate: true,
  },
};

const config = Object.entries(coinsConfig)
  .map(([key, coin]) => {
    if (coin) {
      const coinName = key;
      // 读取默认icon位置
      if (typeof coin.icon === 'undefined' && !coin.icon) {
        coin.icon = `/static/images/coin/icon_${coinName}@2x.png`;
      }
      // 默认全名
      if (!coin.fullName) {
        coin.fullName = key;
      }

      // 中文名， 不存在默认取fullName
      if (!coin.zhName) {
        coin.zhName = coin.fullName;
      }

      if (coin.zendeskLink) {
        coin.zendeskLink = coin.zendeskLink.replace(
          '{helpBaseURL}',
          helpBaseURL,
        );
        coin.zendeskLinkCN = coin.zendeskLink.replace('{zenLang}', 'zh-cn');
        coin.zendeskLinkEN = coin.zendeskLink.replace('{zenLang}', 'en-us');
      }

      if (coin.miningGuidLink) {
        coin.miningGuidLink = coin.miningGuidLink.replace(
          '{helpBaseURL}',
          helpBaseURL,
        );
        coin.miningGuidLink = coin.miningGuidLink.replace(
          '{mainDomain}',
          mainDomain,
        );
        coin.miningGuidLink = coin.miningGuidLink.replace(
          '{oldVerBsvExplorer}',
          oldVerBsvExplorer,
        );
        coin.miningGuidLink = coin.miningGuidLink.replace(
          '{reactAppExplorerUrl}',
          reactAppExplorerUrl,
        );
      }

      // 社群
      if (!coin.socialGroups) {
        coin.socialGroups = {};
      }
      if (!coin.socialGroups.qq) {
        coin.socialGroups.qq = `QQ-矿工交流621211954@2x.png`;
      }

      if (!coin.socialGroups.telegram) {
        coin.socialGroups.telegram = 'https://t.me/btccom_pool';
      }

      // dashboard 配置
      if (!coin.dashboard) {
        coin.dashboard = {};
      }
      coin.dashboard = merge(
        {
          networkStatus: {
            // 显示哪些币种收益
            showHashrateIncomeCoins: [],
            // 是否显示下次难度
            isShowNextDiff: false,
          },
          // 挖矿地址
          miningAddress: {
            // 是否显示nicehash地址
            isShowNicehashAddress: false,
          },
        },
        coin.dashboard,
      );
    }

    return [key, coin];
  })
  .reduce((obj, [key, coin]) => {
    return {
      ...obj,
      [key]: coin,
    };
  }, {});

export default config;
