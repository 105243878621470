import flatten from 'flat';
const pageMessage = {
  pages: {
    dashboard: {
      EarningsM: '{coinType} Mining Earnings',
      accountEarning: 'Account Earnings',
      active: 'Active',
      activeMiner: 'Active',
      amoutPaid: 'Total Amout Paid',
      announcement: 'Announcement',
      cannotConnect: 'Cannot connect?',
      urlConfigGpu: ' supports normal GPU miner',
      urlConfigNicehash: ' optimized for Nicehash',
      urlConfigGpuDcr: ' supports Antminer DR3，Claymore，gominer',
      urlConfigNicehashDcr: ' supports Nicehash，Innosilicon miner',
      customerTitle: 'After-sale Service',
      dailyHashrate: 'Daily',
      dashboard: 'Dashboard',
      earning: '{coinType} Earnings',
      eventConnected: 'connected',
      eventDisconnected: 'disconnected',
      eventMiner: 'Miner',
      eventName: 'event',
      eventSkip: 'Skip',
      eventTitle: 'Events',
      eventlatestLogs: 'The events below are past',
      hastrateChart: 'Hashrate Chart',
      history: 'History',
      hour: '{n} H',
      hours: 'Hours',
      inActive: 'Inactive',
      lastPaymentTime: 'Last Payment Time',
      markAllRead: 'Mark All Read',
      minerContent:
        'Access to local network and login the background system of miners, fill in the miner name and save the profile. The miners will be added to BTC.com Pool in a few seconds.<br/>Miner ID settings: {sub_account}.001, {sub_account}.002, etc; each miner ID corresponds to a miner.',
      minerNameStand: `Miner's name standard: sub-account+'.'+number, for example, if your sub-account is {sub_account}, then your miners could be set up as {sub_account}.001, {sub_account}.002. Each miner has its own name.`,
      minerName:
        '* Set &lt;sub-account&gt;.&lt;miner_ID&gt; on your miners, like {sub_account}.001, {sub_account}.002, etc.',
      miner: 'Miner',
      miners: 'Miners',
      miner1: 'I-Type Miner',
      miner2: 'II-Type Miner',
      miningAddress: 'Mining Address',
      miningEarnings: '{coinType} Mining Earnings',
      minute: 'M',
      more: 'More',
      networkDiff: 'Network Diff',
      networkHashrate: 'Network Hashrate',
      networkStatus: 'Network Status',
      nextDifficult: 'Estimated Next Diffculty',
      noMiner:
        'Add miners to BTC.com pool and start mining. Recommend the use of ',
      btcTools: 'BTC Tool.',
      noEvent: 'No event notification!',
      nowHashrate: 'Real-time',
      pendingPayouts: 'Pending Payment',
      poolHashrate: 'Pool Hashrate',
      realHashrate: '{coinType} Hashrate',
      realtime: 'Real Time',
      timeRemain: 'Time Left',
      title: 'Dashboard - BTC.com Pool',
      today: 'Today Estimate',
      unpaid: 'Unpaid',
      yesterday: 'Yesterday',
      yesterdayEarnTip:
        'Refers to the earnings from 0:00-24:00 the day before UTC time.',
      todayEarnTip:
        'The estimated income of hashrate that only mine BTC (the algorithm of Sha256)',
      charts: {
        hashrate: 'Hashrate',
        minerCharts: 'Miner Hashrate Charts',
        myCharts: 'My Hashrate Charts',
        poolCharts: 'BTC.com Pool Hashrate Charts',
        reject: 'Rejected',
        time: 'Time',
      },
      yesterday_earn: 'Yesterday Earnings',
      yesterday_hashhrate: 'Yesterday Accepted Hashrate',
      earnings_per_100T: 'Earnings per 100T',
      //职责声明
      statement:
        '* You are not allowed to access (or attempt to access) this service by any means other than through the means we provide or have indicated to be used, and you specifically agree not to access (or attempt to access) this service via any illegal or unlawful means or use this service to support any illegal or unlawful activities or purpose. By entering this website and signing up for this service, you acknowledge and agree that you are a person to whom this service may be lawfully provided in accordance with the laws of jurisdiction in which you are located.',
      // 收益预估
      estimatedEarnings: {
        title: 'Estimated earnings',
        hours24Earnings: 'Estimated latest 24 hours earnings',
        tip24:
          'Based on the average difficulty of the currency in the latest 24 hours',
        realTimeEarnings: 'Estimated real-time earnings',
        realTimeEarningsTip:
          'Based on the newest mining difficulty of the currency',
      },
    },
    subAccount: {
      title: 'Create a sub-account - BTC.com pool',
      createWorker: 'Create a sub-account',
      noNode: 'You didn’t choose the node yet!',
      noCoinType: 'You didn’t choose the mining coin yet!',
      selectRegion: 'Select your region',
      setWorker: 'Set a sub-account name',
      enterWorker: 'Enter the sub-account name',
      enterAddress: 'Enter the Address',
      coinTypeEx:
        'BTC.com Pool now supports mining {supportCoins}, other coins will come soon, just look forward it.',
      regionEx:
        'BTC.com Pool deploys servers around the world, and the nearest server will bring you the best experience.',
      workerEx:
        'This sub-account will be tied to the region above. You can create up to 2000 sub-accounts. Set <sub-account>.<miner_name> on your miners, like subaccount.001, subaccount.002, etc.',
      workerCaution:
        "* Caution: Each sub-account can mine in only one region. This sub-account you are creating can't be used in other regions. If you want to mine in other region, please create another sub-account in other region.",
      setAddress: 'Set a withdraw address',
      save: 'Create and Mining',
      verifyWorker: '3-20 letters or numbers',
      option: 'Optional',
      optional:
        'Optional. BCH address is similar to BTC‘s, Please pay attention and don‘t fill in the wrong address.',
      'optional-BTC':
        'Optional. BCH legacy address is similar to BTC‘s, Please pay attention and don‘t fill in the wrong address.',
      'optional-BCH':
        'Optional. BCH legacy address is similar to BTC‘s, Please pay attention and don‘t fill in the wrong address. recommend to use new address',
      'optional-UBTC':
        'Optional.BCH legacy address is similar to UBTC‘s,Please pay attention and don‘t fill in the wrong address.',
      'optional-SBTC':
        'Optional.BCH legacy address is similar to SBTC‘s,Please pay attention and don‘t fill in the wrong address.',
      'optional-coin':
        'Optional：Please pay attention and don‘t fill in the wrong address.',
      selectCoinType: 'Select your mining coin',
      automatic: 'Automatic',
      recommandWallet: 'we recommand you to use BTC.com Wallet',
      withdrawToExchange: 'Exchange help',
      withdrawToWallet: 'Wallet help',
    },
    tools: {
      // title: 'Tools - BTC.com Pool',
      // tools: 'Tools',
      // fppsPps: 'FPPS vs PPS Dynamic Comparison',
      // aboutFpps: 'What’s good about FPPS?',
      // charts: 'Charts can tell everything.',
      // watchCharts: 'Watch Charts',
      // miniCalc: 'Mining Profit Calculator',
      // miniCalcExpress:
      //   'Calculate the mining profit combined with the price, difficulty, hash rate, electricity costs, power use, etc',
      // miniMiniCalc: 'Mini Mining Profit Calculator',
      // miniMiniCalcExpress: 'Quickly calculate mining profit in the easiest way',
      // strat: 'Start',
      // btcTool: 'BTC Tool',
      // btcToolIntroduce: "Batch process for miners' configuration efficiently.",
      // download: 'Click to download',
      btcSmartAgent: 'BTC Smart Agent',
      agentExpress: 'Efficient and Transparent <br/>Single Worker Visible',
      agentExpressBanner:
        'Efficient and Transparent <i style="margin-right:20px"></i> Single Worker Visible',
      bit: 'bit',
      document: 'Windows Help Doc.',
      poolApp: 'Full Featured BTC.com Pool App',
      poolRealTime: 'View pool & miner data in real-time',
      poolOpeation: 'Delete/modify miners directly',
      poolComming: 'Event alert function coming soon',
      poolDownlaod: 'Click to download',
      title: 'Tools - BTC.com Pool',
      tools: 'Tools',
      fppsPps: 'FPPS vs PPS Dynamic Comparison',
      aboutFpps: 'What’s good about FPPS?',
      charts: 'Charts can tell everything.',
      watchCharts: 'Watch Charts',
      miniCalc: 'Mining Profit Calculator',
      miniCalcExpress:
        'Calculate the mining profit combined with the price, difficulty, hash rate, electricity costs, power use, etc',
      miniMiniCalc: 'Mini Mining Profit Calculator',
      miniMiniCalcExpress: 'Quickly calculate mining profit in the easiest way',
      start: 'Start',
      btcTool: 'BTC Tool',
      btcToolIntroduce: "Batch process for miners' configuration efficiently.",
      ipToolsTitle: 'Set Miners Static IP Tools',
      ipToolsDes:
        'Support all Antminers、occupied IP Configure、configure direct connection to the pool',
    },
    miners: {
      createGroup: 'Create a New Group',
      default: 'DEFAULT',
      addGroup: 'Create a New Group',
      deleteGroupTitle: 'Delete Group',
      editGroupTitle: 'Edit Group',
      addGroupInfo:
        'Note: The selected miners will be automatically added to the new group.',
      groupNameLabel: 'Group Name',
      addGroupEmpty: 'The group name cannot be empty.',
      groupRejected: 'Real time rejection rate',
      groupNameLimit: 'The group name must be between 3 and 20 digits',
      all: 'All',
      inactive: 'Inactive',
      active: 'Active',
      dead: 'Dead',
      moveTo: 'Move To',
      remove: 'Remove',

      allTooltip:
        'Includes: Active Miners ({active}) + Inactive Miners ({inactive})',
      inactiveTooltip:
        'The number of miners that did not submit the calculation results (Share) for more than 10 minutes.',
      activeTooltip:
        'The number of miners that normally submit the calculation results (Share)',
      deadTooltip:
        'The number of miners that have not submitted the calculation result (Share) for more than 24 hours',

      home: 'Home',
      miners: 'Miners',

      workerName: 'Worker Name',
      Hashrate: 'Real Time',
      Rejected: 'Rejection Rate',
      dailyHashrate: 'Daily',
      firstShare: 'First Connection',
      LastShare: 'Last Share',
      groupName: 'Group Name',

      delMillTitle: 'Are you sure you want to delete these miner information?',
      delMillContext:
        'This operation will delete all the miners you have selected, please operate carefully.',

      delGroupTitle: 'Are you sure to delete the {name} group?',
      delGroupContext: 'The miners in the group will all move to the default',
      delMillFailed: 'Failed to delete',

      moveTitle: 'Move miners',
      notChecked: 'Select miners and take batch operation.',
      moveContext:
        'This will move all selected miners to the {groupName} group.',
      moveFaild: 'Cannot move to the current group',

      searchPlaceholder: 'Enter the worker name',
      reportTime: 'Generation time',
      refresh: 'Refresh',
      editGroupName: 'Edit group name',
      workListEmpty: 'No miner detected in this group',
      exist: 'The group name has been taken.',

      goNewVersion: 'Return to new version',
      goOldVersion: 'Return to old version',
      dataExport: `"{name}" Groups' miners hashrate data output`,
      exportTip:
        'Please select the time interval of the data to be exported, and the data can only be exported within 30 days at most. The exported file format is CSV.',
      exportTip2: `The calculation time of the current hashrate is from 00:00 to 23:59, and the calculation time of today's hashrate is from 00:00 to export time.`,
    },
    minerInfo: {
      title: 'Miners - BTC.com Pool',
      miners: 'Miners',
      hashrate: 'Single Hashrate',
      runningCondition: 'Running Condition',
      min: 'M',
      hour: '{n}H',
      hashrateAxis: 'Hashrate',
      rejected: 'Rejected',
      status: 'Status',
      lastShare: 'Last Share',
      lastShareIP: 'Last Share IP',
      hashrateChart: 'Hashrate Chart',
      realTime: 'Real Time',
      trans: {
        seconds: 's',
        mins: ' mins',
        hours: ' hours',
        ago: ' ago',
        ACTIVE: 'Active',
        INACTIVE: 'Inactive',
        DEAD: 'Dead',
      },
    },

    earningHistory: {
      title: 'Earnings History - BTC.com Pool',
      earn: 'Mining Earnings',
      Unpaid: 'Unpaid',
      EarningsToday: 'Estimated Mined',
      EarningsTodayTip:
        'This refers to the estimated amount of PPS earnings that has been mined from 0:00(UTC) to this moment, and this amount may deviate from your actual earnings.',
      EarningsYesterday: 'Earnings Yesterday',
      Time: 'Mining Date',
      NetworkDifficulty: 'Difficulty',
      Earnings: 'Earnings',
      Payment: 'Payment Time',
      payWithOTCAmount: 'Pay with {otcType} : {amount}',
      Mode: 'Mode',
      Address: 'Address',
      PaidAmount: 'Earnings',
      yesterday:
        'Refers to the earnings from 0:00-24:00 the day before UTC time.',
      unpaidReason: {
        PENDING_NON_ADDRESS:
          "If you haven't set the address, your earnings will be saved in balance",
        PENDING_CHANGED_ADDRESS:
          'The payment will be done in 48 hours if the address is changed. Warning: your account(email / phone number) probably has been attacked if the address is not changed by yourself. Please register a new account with other emails or phone numbers immediately and switch your miners to the new one.',
        PENDING_NOT_ENOUGH:
          'The amount is less than the minimum payment as you set(the default is :limit ), it will be deposited into your balance.',
        PENDING_POOL_SETTLE_FAULT:
          'As a result of the mine pool, this part of the amount will be issued by mistake and will not be deposited in the future.',
      },
      depositeAmount: 'Saved',
      'diff-chg': 'Diff Chg: ',
      'equals-pps': 'Equals PPS',
      Chg: 'Chg.',
      status: 'Payment Status',
      paid: 'Paid',
      waitingPay: 'Waiting',
      reason: 'Why',
      fppsMoreEarn: '{mode} Extra:',
      fppsContain:
        'Compared with PPS, the earning of the {mode}  model increased by about {pps96}',
      noEarningsInformation: 'No earnings information',
      earningDetail: 'Earning Details',
      extraProfits: 'Extra profits',
      autoSwap: 'Auto-swap',
      original: 'Original currency',
      Equivalent: 'Equivalent of BTC',
      Swapped: 'Swapped',
      swapping: 'Swapping',
      otcEarningTip:
        'The settlement of Smart Pool mode’s target currency earnings by auto-swap replies on real exchange rate of auto-swap in Matrixport on  the billing day.',
      originEarningTip:
        'The settlement of Smart Pool mode’s target currency earnings by original currency will be calculated according to  exchange rate of auto-swap in Matrixport on the billing day 8:00AM.',
      exportEarningsData: 'Export Earnings Data',
      exportTip:
        'It can support up to 180 days of data, and the exported file format is XLS.',
      originStatusTip:
        'Earnings are swapped to target currency by Auto-swap. You can check more details in Earnings Page of Smart Pool mode. ',
      swappingTip:
        'The current income is being exchanged or the minimum amount of exchange is not reached due to the low income',
      swappedTip:
        'Earnings are swapped to target currency by Auto-swap. You can check more details in Earnings Page of Smart Pool mode',
      // upgrade TODO 收益改版新增
      settlementHashrate: 'Settlement Hashrate',
      addressRatio: 'Address Ratio',
      addressEarnings: 'Address Earnings',
      paymentTime: 'Payment Time',
      TimeTip:
        'The mining date is in UTC time. For example, January 1, 2020 refers to 00:00:00 on January 1, 2020-00:00:00 on January 2, 2020 in UTC time.',
      EarningsTip:
        'The amount of earnings is calculated by the BTC.com Settlement Center" based on multiple factors: the earnings distribution method, the network difficulty, the settlement hashrate, the base reward and Tx fee reward of Block, and the commission rates etc., there may be deviations in the theoretical earnings you calculated in the earnings calculator.',
      // upgrade TODO 邀请新增
      inviteEarning: 'Commission Earnings',
      inviteWorkerAccount: 'Invitee Sub-account',
      inviteRebateRatio: 'Commission Ratio',
      inviteRebateAmount: 'Commission Amount',
      rebateExportTip:
        'It can support up to 60 days of data, and the exported file format is XLS.',
    },

    //统计
    poolStats: {
      title: 'Pool Statistics - BTC.com Pool',
      poolStats: 'Pool Statistics',
      hashrate: 'Hashrate',
      miners: 'Miners',
      coinType: 'Coin Type',
      found: 'Found',
      hashrateChart: 'Hashrate Chart',
      blocksRelayed: 'Blocks Relayed',
      height: 'Height',
      time: 'Time',
      blocks: 'Blocks',
      blockHash: 'Block Hash',
      ddaScore: 'DAA Score',
      reward: 'Reward',
      day: 'Days',
      region: 'Region',
      lucky: 'Lucky',
      btc: 'BTC',
      nmc: 'NMC',
      zec: 'ZEC',
      bcc: 'Bitcoin Cash',
      bch: 'Bitcoin Cash',
      eth: 'Ethereum',
      dcr: 'Decred',
      etc: 'Ethereum Classic',
      sbtc: 'Super Bitcoin',
      ubtc: 'United Bitcoin',
      grin: 'Grin',
    },

    // 守护者
    guardian: {
      expiredDeadline: ' (:day Automatically deleted)',
      guardianRemark:
        'You can share all (or part) of your sub-accounts information(including hash rate, miner) to other user who can only view, but he cannot modify any settings.',
      createGuardian: 'Add Sharing User',
      remark: 'Remark:',
      user: 'User:',
      manage: 'Manage',
      status: 'Status:',
      theGuardian: 'Share Account:',
      creationDate: 'Creation Date:',
      bounded: 'Bounded',
      datePrevious: 'Date Of Previous Use:',
      delete: 'Delete',
      deleteGuardianLink: ' Delete The Share Account Link',
      createGuardianInvitation: 'Create The Sharing Account Link',
      guardianAccountManagement: 'The Share Account Management',
      modifyRemark: 'Modify Remark',
      validateRemarkEmpty: 'Remark can not be empty',
      enterRemark: 'Add Remark',
      noteInformation:
        'Note: This remark is used to distinguish multiple share accounts, we suggest using the contact information.',
      confirm: 'Confirm',
      create: 'Create',
      cancel: 'Cancel ',
      guardianSuccessfullyCreated:
        'The sharing account invitation link was successfully created',
      copyLink: 'Copy Link',
      authorized:
        'After deleting, others will lose management authorization for your sub-account.',
      noteSendGuardian:
        'Tip:  Please send this link to reliable people who have demand for miner management. This link can be used for once, if the link is expired, please recreate it. ',
      close: 'Close',
      subaccount: 'Subaccount',
      coinType: 'Coin Type',
      region: 'Region',
      guardianStatus: 'Sharing',
      guardianSelectExpress:
        "Through the switch in the sharing status, you can select whether other's account  can manage your subaccount or not. (The default setting is the guardian can visit all your subaccounts.)",
      invitTitle: 'Share My Account To Others',
      acceptTitle: "Other's Account Acceptd by Me",
      losePower:
        'If you delete it, you will lose the authority to manage the subaccount.',
      show: 'Show',
    },
    // 绑定守护者邀请链接
    guardianBind: {
      bindingGuardianInvitation: 'Binding The Share Account Invitation Link',
      advance: 'Advance',
      guardianAccountManagement: 'Binding The Share Account Link:',
      confirmGuardianBind: 'The Share Account Account Management',
      remark: 'Remark',
      enterRemark: 'Add Remark',
      suggestName:
        'We suggest using the name to make the management more convenient. If you leave the field blank, it means you will use phone number or email address by default.',
      guardian: 'Bind',
      cancel: 'Cancel',
      confirm: 'Confirm',
      manageView: 'to manage all of your subaccounts？',
      suggestUsingName:
        'We suggest using name to make management more convenient. If you leave the field blank, it means use phone number or email address by default.',
      sms: 'Phone',
      email: 'Email',
      code: '{mode} verification Code',
      send: 'Send',
    },
    account: {
      subAccountManage: 'Sub-account Manage',
      currentAccount: 'Current Account',
      locationRegion:
        'Your location is in <strong>{coin}</strong> <strong>{region}</strong> region',
      locationEx:
        'Each subaccount corresponds to a fixed icon and area, and the currency and area can not be modified once created. If there are other requirements, you can create a new subaccount.',
      general: 'General Version',
      advance: 'Advanced  Version',
      create: 'Create',
      algorithm: ' Algorithm',
      oneButtonSwitch: 'One-button Switch',
      addOthersCoins: 'Add other coins',
      supportCoins: 'Support',
      hiddenSubAccounts: 'Hidden Sub-accounts Manage',
      hashAlert: 'Running well',
      viewHideSubAccount: 'View hide sub-account',
      addSubAccount: 'Add sub-account',
      recover: 'Recover',
      latestAlert: {
        hashrate: 'Hashrate {symbol} {expect} {unit}',
        miners: 'Miner count {symbol} {expect}',
      },
    },
    settings: {
      subAccount: 'Sub Account',
      currency: 'Currency',
      region: 'Region',
      walletAddressAndMinimumPayment: 'Wallet Address',
      walletAddress: 'Wallet Address',
      shareData: 'Share Accounts',
      shareDataTip:
        "You can share all or part of your sub-account information with others who can only view it but cannot modify it; you can also receive all or part of other people's sub-account information in read-only mode.",
      alertSettings: 'Alert Settings',
      mywatcher: 'Watcher Settings',
      apikey: 'API KEY',
      payWithBtc: 'Pay with BTC',
      //wallet address & minimum payment
      setAddress: 'Please set a {coinType} Address.',
      address: 'Address',
      createAddress: 'Add Address',
      modifyAddress: 'Modify your Wallet Address',
      modify: 'Modify',
      minPay: 'Threshold',
      lastUpdateTime: 'Address update time',
      updateBitconAddressTip:
        'Notice: For security reason, your earnings will be saved as balance for 48 hours.',

      //watcher
      old: 'Old',
      new: 'New',
      continue: 'Continue',
      emptyAddress: 'Address can not be empty',
      addAddress: 'Set',
      enterCode: 'Enter the code',
      newBit: 'Your new address',
      way: ' We will send you a code to confirm this modification,how would you like to receive it?',
      sendCode: 'We have sent you a code to confirm this modification',
      sendVerifyCode:
        'We have {contactType} you a code to confirm this modification',
      sms: 'texted',
      mail: 'mailed',
      mailVia: 'Send the verify-code to mailbox ',
      smsVia: 'Send verify-code to mobile phone ',
      errorLater: 'please try again later ...',
      updateSuccess: 'The address update success!',
      // 多地址收款
      multAddress: {
        tag: 'Multiple Addresses',
        addMultAddress: 'Add Address',
        adjustProportion: 'Adjust Proportion',
        adjustMinPay: 'Adjust Threshold',
        address: 'Address',
        proportion: 'Proportion',
        remark: 'Note',
        lastUpdateTime: 'Newest Time',
        operation: 'Operation',
        emptyText: "You haven't added any address",
        emptyTextForMult: 'No data, up to 20 addresses can be added',
        cancel: 'Cancel',
        nextStep: 'Next Step',
        delete: 'Delete',
        modify: 'Modify',
        newAddressPlaceholder: 'New address',
        addressMaxCountTip:
          'Currently, you can can add up to {maxCount} receiving addresses in a sub-account. If you need to support more addresses, please contact customer service support@connectbtc.com.',
        updateBitconAddressTip:
          'For the safety of funds, the payment will be suspended within 48 hours after this operation, and your receivable earnings will be paid on the first settlement day after 48 hours.',
        emptyRemark: 'No notes added',
        noAddressForAdjust:
          'You have not added any address. Please add a few addresses first before adjusting the ratio or threshold.',
        balanceMoveTip:
          'The unpaid cryptocurrency of the current sub-account will be transferred to "New Address 1", please confirm your operation.',
        confirmModalTitle: 'Safety Verification',
        authentication: 'Authentication',
        operationConfirm: 'Operation Confirm',
        addAddressConfirmTip: 'You are adding {count} addresses',
        modifyProportionConfirmTip:
          'You are adjusting the proportion of each receiving address',
        deleteAddressConfirmTip: 'You are deleting address <br/>{address}',
        selectBalanceMoveAddress: 'Choose an inheritance address',
        selectBalanceMoveAddressTip:
          'The unpaid cryptocurrency of the address being deleted is {balance}{coinType}. After this operation, it will be transferred to the new address selected above in 24 hours.',
        cannotDeleteAddress: 'Unable to delete the address',
        deleteAddressRule:
          'Only addresses with a payout proportion equal to 0.00% can be deleted.',
        // deleteAddressRuleDetail:
        //   '1.The propotion is equal to 0.00%;<br/>2.The smart mining address of  the same sub-account is not set as this address.',
        // usedBySmart:
        //   'The smart mining of the current sub-account selects the current address, so this modification will simultaneously modify the machine gun mining address.',
        operationSuccess: 'Operates successfully',
        validate: {
          emptyAddress: 'Please fill in new address',
          emptyAddressForUpdate: 'Address can not be empty',
          emptyProportion: 'Please fill in the proportion of address',
          emptyProportioAfter: '',
          proportionInvalidate:
            'The proportion of each address must be greater than or equal to 0%, and the sum of all proportions must be equal to 100%',
          maxCount: 'Up to 20 addresses can be set for each sub-account',
          duplicateAddressPrefix:
            'The same address can only be added once, but the address ',
          duplicateAddressSuffix: ' is being added repeatedly.',
          limitAddress: 'This type of address is not supported at this time',
        },
      },
      //min pay
      currentMinPay: 'Current Minimum Payment:',
      minPayAllSubAccount: 'Minimum Payment is equal for all BTC sub-accounts',
      todayUpdateMinPay:
        'Minimum Payment of mining earnings has been changed from today: {minPay}',

      //Share to watcher
      watcher: 'Watcher',
      availableWatchers: 'Available Watchers',
      watcherExpress:
        '* Watchers can view your data via url below, but can not modify your account settings.',
      createWatcher: 'Create New Watcher',
      modifyWatcher: 'Edit',
      authoritySettings: 'Permission Settings',
      setWatcherName: 'Set a name for this watcher',
      watcherName: 'Watcher Name',
      watcherNameTip: '2 ~ 20 characters',
      notEmptyWatcherNote: 'wathcer name can not be empty',
      tips: 'Tips:',
      tip_dashboard:
        '1. "Dashboard" does not include earning information such as "Unpaid" and "Paid";',
      tip_earning:
        '2. "Earnings" includes information such as the "Unpaid" and "Paid" of each address.',
      moreSettings: 'More Settings',
      guardianRemark:
        'You can share all (or part) of your sub-accounts information(including hash rate, miner) to other user  who can only view but not modify any settings.',
      guardian: 'Share Accounts',
      shareAccountsSetting: 'Share Accounts',
      accessKey: 'Access Key',
      generateAK: 'Generate Access Key',
      puid: 'PUID',
      apiDoc: 'API Document',
      confirmDeleteWatcher: 'Confirm to delete the watcher?',
      watcherAuths_Dashboard: 'Dashboard',
      watcherAuths_Dashboard_Tip: '(Excluding Earnings)',
      watcherAuths_Miners: 'Miners',
      watcherAuths_Earnings: 'Earnings',
      watcherAuths_GetCoin: 'Gift Coin',

      // Alert setting
      alertItem: 'Alert Item',
      alertContacts: 'Alert Contacts',
      hashrateAlert: 'Hashrate Alert',
      minerAlert: 'Miner  Alert',
      alertFrequency: 'Alert Frequency',
      alertFrequencyTip:
        'Due to policy reasons, the frequency of SMS alerts in some countries and regions is fixed at once every 24 hours. This is hereby notified.',
      warnMeWhenHashrate: 'Hashrate alert line ≤',
      warnMeWhenActiveMiner: 'Warn me when active miner ≤',
      dontWarnMeTwice: "Don't warn me twice in ",
      hours: 'Hours',
      apply: 'Apply',
      operateFailed: ' Operation failed!',
      contactsList: 'Alert Contacts',
      cancel: 'Cancel',
      ok: 'OK',
      save: 'Save',
      reset: 'Reset',
      newContact: 'Add new contact',
      editContact: 'Edit contact',
      deleteContact: 'Delete contact',
      confirmDeleteContact: 'Confirm to delete the alert contact?',
      name: 'Name',
      phone: 'Phone',
      email: 'Email',
      validateAlert: {
        nameEmpty: 'Name can not be empty',
        phoneRegionFormat: 'Invalid region code',
        phoneFormat: 'Invalid phone number',
        emailFormat: 'Invalid email',
        contactEmpty: 'The email field is required when phone is not present.',
      },
      max_email_contacts: 'Supporting max to 10 mail contacts',
      max_phone_contacts: 'Supporting max to 5 phone contacts',
      enabled_alert_need_hashrate:
        'No hashpower detected, can not enable alert.',
      alertItemUpdateSuccess: 'Alert item update success',

      done: 'Done',

      Okay: 'Okay',

      invalidAddress: 'Invalid address',
      emptyCode: 'Can not be empty',
      wrongCode: 'Wrong code',
      emailVia: 'Email me via',
      textVia: 'Text me via',
      help: 'Help',

      contact: 'Contact:',
      close: 'Close',
      receiveAddress: 'Address:',

      updateAddress: 'Address update time:',
      // pay with btc
      payWithBtcTip:
        '* After the currency conversion turned on, your mining profit will be converted into Bitcoin at a fixed time each day and paid to the following individual BTC address. Normally, it will be UTC time 4:00, and will be notified in special circumstances.',
      convertedCurrency: 'Converted Currency: ',
      setBtcAddressTip: 'Please set a BTC address',
      btcAddress: 'BTC Address: ',
      conversionRate: 'Reference Conversion Rate',
      convert: 'Convert',
      getValue: 'You will get ',
      conversionTip:
        '* Conversion rate is for reference only, subject to actual conversion result.',
      lastUpdate: 'Last updated: ',
      on: 'ON',
      off: 'OFF',
      turnOnPaywith: 'Are you sure you want to turn on the pay with BTC?',
      turnOffPaywith: 'Are you sure you want to turn off the pay with BTC?',
      payOn: 'Turn on',
      payOff: 'Turn off',
      resend: 'Resend',
      send: 'Send Code',
      resendTip:
        'If you do not receive the code, please wait a while or try a few more times',

      // 一键切换 One Switch
      oneSwitch: {
        attention: 'Attention',
        optionCurrentMode: 'Please select the mode',
        switch: 'you are about to switch your hashrate to {destMode}',
        switchAnotherMining: 'You are switching to {destMode} mining.',
        hashsHaveSwitched:
          'Mining hashes have all switched to {destMode} pool.',
        ethSwitchWarning:
          'Warning: If your mining machine does not support switching DAG, it will result in zero revenue. Need to restart the miner manually,To return to normal. (It is known that the AntMiner E3 does not support it. Switching from ETC to ETH requires manual restart).',
        ethSwitchWarningLinkTxt: 'Details.',
        checkSubAccount:
          'Please check your sub-account {subAccountName}- {subAccountRegionNode}',
        enter: 'Enter',
      },
      // 机枪收款地址
      smartAddress: {
        // autoSwapSettlement: 'Auto-swap Settlement',
        original: 'Original currency Settlement',
        autoTip:
          'Currencies generated by Smart Pool will be settled in target currency daily.',
        originalTip:
          'Smart Pool settlement will be settled in BTC, BCH and BSV supported by Smart Pool.',
        originalCurrency: 'Original Currency',
        targetCurrency: 'Target Currency',
        earningsInSmartPool: 'Earnings in Smart Pool',
        activated: 'Activated',
        unactivated: 'Unactivated',
        modifyTip:
          'Target currency of Smart Pool auto-swap settlement is associated with and consistent with the wallet address of the same currency under the identical sub-account.',
        autoHistory: 'Auto-swap history earnings to BTC or not?',
        notice1:
          'For security reason, your earnings will be saved as balance for 48 hours.',
        notice2:
          'Earnings of Smart Pool will be swapped automatically to target currency after activating Auto-swap. Click OK will be deemed to agree with {fundLink}.',
        notice3:
          'The address in the smart mode is associated with and consistent with the single coin address under the same sub-account (if multiple addresses are set, it will be consistent with the first address)',
        originalNotice1:
          'The address in the smart mode is associated with and consistent with the single coin address under the same sub-account (if multiple addresses are set, it will be consistent with the first address)',
        currentPayoutMode: 'Current payout mode: {mode}',
        fundLink: `“License Agreement of BTC.com Currency Auto-swap”`,
        originBtn: 'Activate original currency settlement',
        otcBtn: 'Activate auto-swap settlement',
        otcSuccess:
          'Auto-swap settlement of Smart Pool mode is activated and we will make payment with BTC.',
        otcModalNotice2: `With activating auto-swap history earnings, the previous earnings in the account will be swapped. Without it, only recent earnings will be swapped.`,
        originSuccess:
          'Original currency settlement of Smart Pool mode is activated and we will make payment with original currency mined by hashrate.',
        // 2021-06-04新增
        mode_otc: 'Auto-swap Settlement',
        mode_origin: 'Original currency Settlement',
        settlementMode: 'Settlement Mode',
        settlementModeDescription: 'Settlement Description',
        originDescription:
          'Original currency settlement: Settlement in the actual mining currency, the currency range is BTC, BCH and BSV.',
        autoSwapDescription:
          'Auto-swap settlement: firstly perform dynamic mining of BTC/BCH/BSV according to the algorithm, and then convert them to BTC for settlement.',
        settlementSwitch: 'Settlement Switch',
        originalSettlement: 'Original currency Settlement',
        autoSwapSettlement: 'Auto-swap Settlement',
        currency: 'Currency',
        address: 'Address',
        addressRules: 'Address rules',
        switchAddress: 'Switch address',
        noAddressTip:
          'The {coin}  of the same sub-account has no address added',
        noAddressSelected: 'No address selected',
        selectFrom:
          'Select from the list of BTC addressed of the same sub-account',
        settingTip:
          'Tip: If you want to distinguish the "Address of Smart Pool" from the "Address of sinlge currency of the same sub-account ", please add a new address in the single currency address management and set the proportion is 0%, and then go back here and switch to the new address.',
      },
    },
    homepage: {
      btcSmartAgent: 'BTC Smart Agent',
      agentExpress: 'Efficient and Transparent Single Worker Visible',
      bit: 'bit',
      bit64: 'Win7 or above 64 bit',
      bit32: 'WinXP 32 bit',
      document: 'Windows Help Doc.',
      accessLinuxVersion: 'Access for Linux Version',
      cuttingEdgeTechnology: 'Cutting Edge Technology',
      latestArchitecture: 'Latest & Original Architecture',
      openSourceCode: 'Open Source Code',
      highTestPressure: 'Ultra-high Stress Test',
      lowOrphanedRate: 'Ultra-low Orphaned Block Rate',
      transparentAgent: 'Transparent Agent',
      standardsServices: 'New Standards for Mining Services',
      customerHotline: 'Customer Hotline',
      highHashrateConsultation: 'High Hashrate Consultation',
      professionalCustomerService: 'Professional Customer Service',
      bestVipServer: 'VIP Server with Ultra-high Specification',
      oneToOne: 'One to One Case Analysis',
      appAvailable: 'BTC.com Pool App Supports Managing Miners Easily',
      earningsTimelyEveryday: 'Receive Earnings Timely Everyday',
      transnationalTeamOperation: 'International Team Operation',
      currencies: 'Currencies',
      dailyEarnings: 'Daily Earnings',
      hashrate: 'Hashrate',
      hashrateChart: 'Hashrate Chart',
      explorer: ' Explorer',
      prices: 'Price',
      earningsModels: 'Earnings Models',
      minPayment: 'Payment Threshold',
      help: 'Help',
      notePaytime: "Daily 0:00-8:00 (UTC) pay yesterday's earnings",
      yuanKwhElectric: 'USD/kW·h (Electricity Price) ',
      todayEarnings: "Today's Earnings:",
      piece: 'Piece',
      signIn: 'Sign In',
      signUp: 'Sign up',
      power: 'Power',
      hashrateProfitEst: 'Hashrate Profit Est.',
      hashrateProfitEstTips:
        'There may be a deviation from your actual earnings, which is for reference only.',
      miningProfitEst: 'Mining Profit Est.',
      fee: 'Fee',
      desc: `The world's leading multi-currency comprehensive service mining pool`,
      tutorial: 'Tutorial',
      diff: 'Difficulty',
      nextDifficultyEstimated: 'Next Difficulty Estimated',
      dateToNextDifficulty: 'Date to Next Difficulty',
      Algorithm: 'Algorithm',
      backup: 'Backup',
      whyMe: {
        title: 'Why choose BTC.com?',
        leadingTechnology: 'Leading Technology',
        convenientManagement: 'Convenient Management',
        revenueAssurance: 'Revenue Assurance',
        professionalServices: 'Professional Services',
        desc1: `Open source code, free management software of miners and mining farm, official customized overclock firmware increases revenue significantly, blockchain data and technical service provider.`,
        desc2:
          'Concise app and webpage operation, easy account registration with cell-phone number or email, independent alarm function, revenue address, miners grouping and watcher link of sharing data.',
        desc3: `Real and transparent mining data, advanced FPPS revenue model, powerful settlement center, punctual payment, rain or shine.`,
        desc4: `BTC.com pool do have a professional customer service team, the highest standard of customized VIP services, and the top researchers analyze cases person to person in the industry.`,
      },
      app: {
        title: 'Pool App',
        description: `The world’s leading multi-currency integrated mining pool`,
        property1: `Supports multi-currency mining, multiple sub-account management, 
  and easy management of observer link sharing`,
        property2: `Real-time display of comprehensive currency data,
     faster grasp of mining trends`,
        property3: 'New interactive interface for smoother user experience',
      },
      coinList: {
        eth: {
          earningsModelsTip: {
            FPPS: `The settlement mode of ETH is the FPPS/PPS+ dual mode, and the current mode is the FPPS mode; we will adjust it according to comprehensive factors such as on-chain transaction costs, the share of BTC.com's market-share, market conditions, and user's income.`,
            'PPS+': `The settlement mode of ETH is the FPPS/PPS+ dual mode, and the current mode is the PPS+ mode; we will adjust it according to comprehensive factors such as on-chain transaction costs, the share of BTC.com's market-share, market conditions, and user's income.`,
          },
        },
        ltc: {
          giftCoin: '+DOGE,BEL',
        },
      },
      globalZone: 'Global',
      zilDiff1: 'Shard',
      zilDiff2: 'DS',
      etcMinerAddressTips:
        'The equipment that only mines ETC connects to this mining address',
      zilMinerAddressTips:
        'ETC and ZIL dual mining equipment is connected to this mining address',
    },
    advanceAccount: {
      title: 'Account Management - BTC.com',
      all: 'All',
      btc: 'BTC',
      bch: 'BCH',
      sbtc: 'SBTC',
      ubtc: 'UBTC',
      dcr: 'DCR',
      ltc: 'LTC',
      grin: 'GRIN',
      beam: 'BEAM',
      unit: 'Unit',
      subAccount: 'Sub-account',
      coin: 'Coin',
      hashrate: 'Hashrate',
      guard: 'Note',
      allMiners: 'All Miners',
      activeMiners: 'Active',
      inactiveMiners: 'Inactive',
      deadMiners: 'Dead',
      region: 'Region',
      more: 'More',
      setting: 'Setting',
      totalHashrate: 'Total Hashrate',
      search: 'Search',
      status: 'Status',
      hidden: '已隐藏',
      shareTome: '共享给我的',
      hide: '隐藏',
      recover: '恢复',
      delete: '删除',
      refersh: '刷新',
      export: '导出',
      name: '名称',
      note: '备注',
      manageShareAccount: '管理共享子账户',
    },
    getCoin: {
      tip: {
        btc: 'From now on, you can get Namecoin(NMC), Elastos(ELA) for free if you are mining Bitcoin or Bitcoin cash in BTC.com mining pool.',
        bch: 'From now on, you can get Namecoin(NMC), Elastos(ELA) for free if you are mining Bitcoin or Bitcoin cash in BTC.com mining pool.',
        ltc: 'From now on, you can get Dogecoin（PPLNS） for free if you are mining Litecoin in BTC.com mining pool.',
        bcha: `<p>The BitcoinCash(BCH) hard fork split the Bitcoin Cash network into two new blockchains, BCHA is a new coin. For more information, please visit the <a href="https://www.bitcoinabc.org/" target="__blank">https://www.bitcoinabc.org/</a></p>
          <p><b>Withdrawal period:</b><br/>2020/12/07 00:00 AM to 2021/01/07 00:00 AM(UTC)</p>`,
      },
      beta: 'In closed beta',
      whatsThis: "What's this?",
      address: 'Address:',
      newAddress: 'New Address',
      addressComplete:
        'To protect your earnings, please submit your address for receiving the free token as soon as possible. ',
      bchaAddressComplete:
        'After January 7, 2021, users who do not fill in the address will be cleared of BCHA and will not withdraw. To protect your earnings, please submit your address for receiving the free token as soon as possible. ',
    },
    watcher: {
      expired: 'Watcher authority expired',
      incorrect: 'Watcher link error',
    },
    activity: {
      pointsToday: 'Points Today',
      pointsSum: 'Sum',
      ranking: 'Ranking',
      earnings: 'Earnings',
    },
    minerRanking: {
      title: 'Mining Profit Estimate',
      electric: 'Charge/Day',
      dailyEarning: 'Daily Profit',
      priceShutdown: 'Shutdown Price',
      ALL: 'ALL',
      CNY: 'CNY',
      USD: 'USD',
      desc: 'Profit Descending',
      asc: 'Profit Ascending',
      electricPercent: 'Electrical Fee Ratio',
      searchTip: 'Enter Miner',
      updated: 'Updated at',
      coin: 'Coins',
      emptyShow: ' No eligible miners',
      rank: 'Rank',
      miner: 'Miner',
      power: 'Power',
      hashrate: 'Hashrate',
      rev24h: 'Rev.24h',
      energy: 'Electrical Fee',
      incomeWithdoge: 'Inclusive of DOGE',
      incomeWithdogeAndBel: 'Inclusive of DOGE+BEL',
    },
  },
};
const pageMessageFlatten = flatten(pageMessage);
export default pageMessageFlatten;
