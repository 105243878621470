import flatten from 'flat';
const inviteMessage = {
  invite: {
    inviteTitle: 'Invitation Reward',
    bindPhoneTip:
      'Bind phone number to participate in, both sides get commission, permanently valid.<br>Please go to<span style="font-weight:500;color:#333"> Personal Center -> Account Settings </span> to bind your phone number.',
    needStep: 'One more Step',
    createInviteAccountLoadingMessage:
      'Creating your exclusive invitation code and sub-account...',
    heading:
      'Invite friends to mine together and <br>get a <span style="color:#0A66C2">0.2% </span>rebate reward',
    cutoff: '<span style="color:#0A66C2">5% off </span>commission for invitees',
    ruleDetails: 'Rule Details',
    totalRebate: 'Total Commission',
    totalRebateTip:
      'The commission is settled in the cryptocurrency, here is the fiat currency valuation, the valuation method is to multiply each payable cryptocurrency by the exchange rate of 0 o’clock (UTC+0) on the settlement date, and then add the sum.',
    details: 'Details',
    totalAccept: 'Invitees',
    minedAccept: 'Mined Invitees',
    inviteCode: 'Invitation Code',
    copy: 'Copy',
    inviteLink: 'Invitation Link',
    acceptInviteCode: 'Invited Code',
    acceptInviteCodeTip:
      'You are an invited miner and enjoy a 5% discount on the mining pool rate. You can check it in the Pool Dashboard > Earnings > Earning Details.',
    generatePoster: 'Generate Poster',
    inviteRecord: 'Invitation Record',
    export: 'Export',
    rebateRecord: 'Commission Record',
    invitee: 'Invitee',
    inviteeStatus: 'Status',
    'inviteeStatus-unlocked': 'Dismissed',
    'inviteeStatus-registered': 'Registered',
    'inviteeStatus-mined': 'Mined',
    registerTime: 'Invited Time',
    miningTime: 'Start Mining',
    miningTimeTip:
      'When the invitee starts mining on the first day after receiving your invitation, UTC time is adopted.',
    coinType: 'Currency',
    region: 'Mining Region',
    inviteSubAccount: 'Commission Sub-account',
    rebatePercent: 'Commission Ratio',
    op: 'Operate',
    originCoinCount: 'Amount',
    specialSubAccountTitle:
      "We've created some special sub-accounts for you to receive commission reward. After your invitees start mining, the commission of mining earnings will be settled to your corresponding sub-accounts in different currencies and different regions.",
    specialSubAccountTip:
      'By default, commission sub-accounts for the US and Europe of the BTC currency are generated. When your invitee creates a mining sub-account for other currencies, we will generate a corresponding special sub-account for you.',
    rebateOpAddress: 'Address Management',
    rebateOpDetail: 'Commission Details',
    downloadPoster: 'Download',
    noData: 'No data',
    gotoBind: 'Go to bind',
    boundPhone: "I've bound it",
  },
};
const inviteMessageFlatten = flatten(inviteMessage);
export default inviteMessageFlatten;
